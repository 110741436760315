.course-details-summary{
    background-color: #FFF8E1;
    padding: 32px 32px;
    border-radius: 16px;
}
.purchased_course_name_lms{
    font-size: 28px;
    font-weight: 500;
}
.course-details-divider{
    height: 40px;
    width: 3px;
    background-color: #FFEBB0;
}

.lms-active{
    background-color: #E5DFEC;
    color:#291640;
    border-radius: 8px;
    text-decoration: none;
    padding: 8px 24px;
}



.primary-info-loader{
    width: 100%;
    height: 250px;
    border-radius: 16px;
}
.primary-tab-content-loader{
    width: 100%;
    height: 500px;
    border-radius: 16px;
}
.primary-nav-loader{
    width: 100%;
    height: 40px;
    margin: 24px 0;
    border-radius: 8px;
}



@media only screen and (min-width:577px) and (max-width: 1100px){
    .purchased_course_name_lms{
        font-size: 26px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 576px) {
    .purchased_course_name_lms{
        font-size: 22px;
        font-weight: 500;
    }
}
