.book-details-top-section{
    margin-top: 36px;
    border-left: 1rem solid #532D80;
    background-color: #EfEFEF;
    padding: 70px 50px 45px 45px;
}

.book-name{
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
}

.writer-name, .book-language, .book-sector{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.writer-name span, .book-language span, .book-sector span{
    font-size: 20px;
    font-weight: 400 !important;
}

.book-price .actual-price{
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 0;

}

.book-price .book-offer-price{
    font-size: 51px;
    font-weight: 800;
    color: #532D80;
    margin-bottom: 0;
}

.short-about-book{
    font-size: 20px;
    line-height: 1.7rem;
    font-weight: 500;
}

.btn-book a {
    text-decoration: none;
    font-size: 20px;
    background-color: #FFCE39;
    padding: 8px 26px;
    color: black;
    font-weight: 500;
    border-radius: 5px;
}

.profileTab #book-details-description-1{
    width: 50%;
}

.profileTab #book-details-description-2{
    width: 50%;
}

@media only screen and (max-width: 576px) {
    .book-name{
        margin-top: 1rem;
    }
    .btn-book a {
        font-size: 16px;
        background-color: #FFCE39;
        padding: 5px 10px;
    }

    .book-details-top-section{
        margin-top: 5px;
        border-left: 1rem solid #532D80;
        background-color: #EfEFEF;
        padding: 10px 10px 45px 10px;
    }

    .book-price .actual-price{
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 0;
    }
    
    .book-price .book-offer-price{
        font-size: 35px;
        font-weight: 800;
        color: #532D80;
        margin-bottom: 0;
    }

    #book-details-description-1 button{
        font-size: 18px !important;
    }

    #book-details-description-2 button{
        font-size: 18px !important;
    }
}
@media only screen and (max-width: 334px) {
    .btn-book a {
        font-size: 14px;
        display: flex;
        justify-content: center;

    }

    
}