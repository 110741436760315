.blog-title{
    font-size: 20px;
    font-weight: 500;
    margin-top: 4px;
}
.blog-date{
    font-size: 16px;
    color: #532D80;
    font-weight: 600;
    display:table-cell;
    vertical-align:middle;
    text-align:center;

}

#absolute {
    position:absolute;
    display:table;
}

.centerd {
    display:table-cell;
    vertical-align:middle;
    text-align:center;
}
.popular-blog-card{
    text-decoration: none !important;
}
.popular-blog-card:hover{
    color: #6B7280 !important;
}

@media only screen and (max-width: 576px) {
    .blog-title{
        font-size: 20px;
    }
}