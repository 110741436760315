.reve-chat-wrapper {
  transition: all 60s ease-in-out; /* Transition effect for 1 minute */
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden; /* Hide when closed */
}

.reve-chat-wrapper.open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible; /* Show when open */
}
