.btn-assignment-start {
    border-radius: 8px;
    text-decoration: none;
    padding: 10.5px 24px;
    font-size: 16px;
    font-weight: 500;
    background-color: #532D80;
    color: white;
    border: 1px solid #532D80;
}
.btn-assignment-start:hover {
    color: white;
}

.btn-instruction-close {
    border: 1px solid #E5DFEC;
    border-radius: 8px;
    text-decoration: none;
    padding: 10.5px 24px;
    font-size: 16px;
    font-weight: 500;
    color: #532D80;
    background-color: white;
}

.instruction-title{
    font-size: 16px;
    font-weight: 600;
    width: 15%;
}
.assignment-instruction-details{
    font-size: 16px;
    font-weight: 400;
}