.result-offcanvas-section{
    border: 1px solid #FFEBB0;
    border-radius: 16px;
    background-color: #FFFDF5;
    padding: 24px;
}

.result-student-name{
    font-size: 24px;
    font-weight: 500;
    color: #525252;
}

.total-percentage{
    font-size: 32px;
    font-weight: 500;
    color: #46C35F;
}
.result-top-divider{
    width: 1px;
    height: 40px;
    background-color: #CCA52E;
}
.result-exam-name{
    font-size: 20px;
    font-weight: 500;
    color: #232026;
}

.result-subject-name{
    font-weight: 400;
    color: #656367;
}
.result-exam-count{
    color: #918F92;
    font-size: 400;
}

.result-marks{

    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 15px 10px 10px 10px;
    font-weight: 600;
    font-size: 20px;
    border: 1px solid #46C35F ;
    background-color: #46C35F;
    text-align: center;
    color: #fff;

}

.divider-border {
    height: 1px;
    background-image: linear-gradient(90deg, #E9E9E9, #E9E9E9 75%, transparent 75%, transparent 100%);
    background-size: 8px 1px;
    border: none;
  }