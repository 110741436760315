.vertical-menu {
    width: 160px !important;
    background-color: #F4F1EB !important; 
    box-shadow: none !important; 
    margin-top: 3.7rem !important; 
    overflow-y: scroll !important;
}

.sidebar-icon{
    height: 35px;
    width: 35px; 
    margin-left: auto; 
    margin-right: auto;

}

.profile-img{
    height: 80px;
    width: 100px; 
    margin-left: auto; 
    margin-right: auto;
}

.site-bar-text{
    color: black;
    font-size: 18px;
    text-decoration: none;
}
.vertical-menu .sidebar ul .sider-bar-items li .active p{
    color: #532d80;
}

.vertical-menu .sidebar ul .sider-bar-items li .active .sidebar-icon{
    filter: brightness(0) saturate(100%) invert(23%) sepia(44%) saturate(1871%) hue-rotate(240deg) brightness(82%) contrast(97%);
}
.icon-color{
    color: #000000;
}

@media screen and (max-width: 576px) {
    .icon-size{
        width: 20px;
        margin-top: -7px;
        padding: 0;
    }
    .side-bar{
        margin-top: -5px;
    }
}

/* .vertical-collpsed .vertical-menu .sidebar ul .sider-bar-items li a p{
    display: none;
} */

/* .vertical-collpsed .vertical-menu .sidebar ul .sider-bar-items li a .profile-img{
    width: 100px;
    height: 80px;
}
.vertical-collpsed .vertical-menu .sidebar ul .sider-bar-items li a .sidebar-icon{
    width: 35% !important; 
}

.vertical-collpsed .vertical-menu{
    width: 100px !important;
} */



.sidebar-footer{
    position: absolute;
    bottom: 0;
    list-style-type: none;
    }

@media only screen and (max-width: 576px) {

    .vertical-menu {
        width: 0px !important;
    }
    .vertical-menu .sidebar{
        background: #ffffff;
        position: fixed;
        margin: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 8% !important;
    }

    .vertical-menu .sidebar ul .sider-bar-items{
        display: table;
    width: 100%;
    }

    .sider-bar-items li{
        float: left;
      width: 20%;
      list-style-type: none;
      text-align: center;
    }

    li .dashboard-nav{
        display: block;
        padding: 5px;
        height: 50px;
    }

    .dashboard-nav img{
        height: 20px;
        width: 20px;
    }

    .dashboard-nav p{
        font-size: 12px;
    }
    .dashboard-nav-profile{
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .sidebar-name{
        margin-bottom: 8px;
    }


    

}


.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
