.details-subject-section{
    border: 1px solid #E9E9E9;
    border-radius: 16px;
    padding: 24px;
}
.course-video-details-section{
    height: 240px;
    width: 480px;
    min-height: 150px;
    border-radius: 8px;
}