.lecture-section{
    width: 70%;
    margin: 0 auto;
}
.lecture-topic-name{
    font-size: 20px;
    font-weight: 500;
    color: #4F4D51;
}
.published-title{
    color: #918F92;
    font-size: 12px;
}
.published-person{
    font-size: 16px;
    font-weight: 500;
}
.lecture-published-section{
    height: 50px;
    width: 2px;
    background-color: #F4F4F4;
}

.lecture-content-section{
    /* display:inline-block; */
    border: 1px solid #E9E9E9;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
    text-decoration: none;
}
.content-link-section{
    padding: 7px 12px;
}

.lecture-video{
    width: 100%;
    border-radius: 16px;
}
.lecture-video-section{
    width: 100%;
    height: 500px;
    border-radius: 16px;
    
}

.back-btn{
    border-radius: 5px;
    text-decoration: none;
    padding: 5px 6px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #532D80;
}
.back-btn:hover{
    border-radius: 5px;
    text-decoration: none;
    padding: 5px 6px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #532D80;
}