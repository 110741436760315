.btn-assignment-submit-and-close {
    border: 1px solid #BDBCBE;
    border-radius: 50%;
    padding: 2px 4px;
}

.show-assignment-name {
    font-size: 24px;
    font-weight: 500;
}

.question-time {
    background-color: #F27321;
    color: white;
    padding: 0 7px;
    border-radius: 16px;
    width: 82px;
}

.question-count {
    background-color: #FFCE39;
    color: #00000080;
    padding: 0 7px;
    border-radius: 16px;
    width: 82px;
}

.remain-time,
.remain-question {
    font-size: 20px;
    font-size: 500;
}

.single-question {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 32px;
    
}
.single-question p{
    font-family: "Medium" !important;
}

.option {
    border-radius: 8px;
    margin-bottom: 13px;
}

.option-width {
    width: 30%;
}

.question-option-shadow {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0%), 0 2px 5px 0 rgb(0 0 0 / 10%);
}

.btn-leave-assignment {
    border: 1px solid #D0202F;
    border-radius: 8px;
    text-decoration: none;
    padding: .7rem;
    width: 20%;
    font-size: 16px;
    font-weight: 500;
    color: #D0202F;
    background-color: white;
}
.btn-leave-assignment:hover{
    background-color:#D0202F;
    color: white;
}

.btn-next-question {
    border: 1px solid #BDBCBE;
    border-radius: 8px;
    text-decoration: none;
    padding: .7rem;
    font-size: 16px;
    width: 20%;
    font-weight: 500;
    color: white;
    background-color:#BDBCBE;
}
.btn-next-question:hover{
    border: 1px solid #532D80;
    background-color:#532D80;
}


.single-question-type{
    background-color: #FDAE61;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 600;
}


/* .preference {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem;
} */

.preference {
  display:block;
  height:100%;
  width: 100%;
  padding:1rem;
  border-radius: 4px;
}

/* .sticky-bottom{
    position: 'sticky',
      bottom: 0,
      backgroundColor: 'lightgray',
      padding: '10px'
} */
