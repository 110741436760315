.course-feature {
    width: 100%;
    background-size: cover;
    background-position: center;
    margin-top: 4rem; /* equivalent to tw-mt-16 */
    border-radius: 1.5rem; /* equivalent to tw-rounded-3xl */
}

@media (max-width: 767px) {
    .course-feature {
        height: auto;
        background-image: url('../../../../../images/HomePageImage/courserFeatureSmBg.png');
    }
}

@media (min-width: 768px) {
    .course-feature {
        height: auto;
        background-image: url('../../../../../images/HomePageImage/courseFeatureLgBg.png');
    }
}
