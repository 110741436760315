@font-face {
  font-family: "KohinoorBangla";
  src: url("./fonts/KohinoorBangla-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "KohinoorBangla";
  src: url("./fonts/KohinoorBangla-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "KohinoorBangla";
  src: url("./fonts/KohinoorBangla-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "KohinoorBangla";
  src: url("./fonts/KohinoorBangla-Semibold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "KohinoorBangla";
  src: url("./fonts/KohinoorBangla-Light.otf") format("opentype");
  font-weight: 300;
}

.balooda-regular {
  font-family: "Regular";
}

.epilogue-font {
  font-family: "Epilogue", sans-serif;
}

.balooda-medium {
  font-family: "Medium";
}

.balooda-bold {
  font-family: "Bold";
}

.balooda-semibold {
  font-family: "SemiBold";
}

.balooda-extrabold {
  font-family: "ExtraBold";
}

/* Additional Styles */
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.youtube_video {
  width: 100%;
  margin: 30px 0;
}

.youtube_video iframe {
  width: 100%;
  height: 500px;
  border-radius: 15px;
}
