.tomorrow-course-topic{
    background-color: #F27321;
    border-radius: 0.3rem;
    font-size: 14px;
}

.tomorrow-mentor-name{
    height: 60px;
    width: 60px;
    border-radius: 0.5rem;
}

@media only screen and (min-width: 1235px) and (max-width: 1335px){
    .tomorrow-live-class-date{
        font-size: 14px;
    }

}
@media only screen and (min-width: 1024px) and (max-width: 1234px){
    .tomorrow-live-class-date{
        font-size: 12px;
    }
    .tomorrow-course-topic{
        font-size: 12px;
    }

}


@media only screen and (max-width: 576px) {
    .tomorrow-course-topic{
        font-size: 12px;
    }
    .tomorrow-mentor-name{
        height: 46px;
        width: 40px;
    }
    .tomorrow-live-class-date{
        font-size: 12px;
    }
}