.logo-header{
  height: 35px;
}
.page-container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 ;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.d_flex {
    display: flex;
    align-items: center;
  }
  
  .Social_sideIcon {
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 999;
  }
  
  .Social_sideIcon a {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 5px 0;
  }
  
  .Social_sideIcon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .BkashLeads {
    padding: 0;
    padding-bottom: 50px;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header .left img {
    height: 100px;
  }
  
  .header .right img {
    height: 8p0x;
  }
  
  .BkashLeads__video {
    margin-top: 20px;
  }
  
  .BkashLeads__video iframe {
    width: 100%;
    height: 600px;
    border-radius: 15px;
  }
  
  .BkashLeadsForm {
    padding: 0 3rem;
    position: relative;
    z-index: 99;
  }
  .success-msg {
    background-color: rgb(245, 210, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .success-img {
    width: 60px;
    background-color: rgb(245, 210, 245);
    border-radius: 30px;
  }
  .success-img h3{
    margin-left: 15px;
    margin-top: 20px;
  }
  .BkashLeadsForm h3 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .custom_input label {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 10px;
    color: #212529;
  }
  
  .custom_input label span {
    color: red;
    margin-left: 2px;
  }
  
  .custom_input input,
  .custom_input select,
  .custom_input textarea {
    width: 100%;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #212529;
  }
  
  .BkashLeadsForm form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .custom_input {
    width: 48%;
    margin-top: 20px;
  }
  
  .custom_input.full_width {
    width: 100%;
  }
  
  .custom_input__child {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
  }
  
  .custom_input__child input {
    height: 18px;
    width: 18px;
    border-radius: 15px;
    cursor: pointer;
  }
  
  .custom_input__child label {
    margin-bottom: 0;
    cursor: pointer;
  }
  
  .custom_input.full_width.button {
    text-align: center;
  }
  
  .custom_input button {
    border: 0;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 10px;
    background: rgb(110, 52, 182);
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    transition: all linear 0.2s;
  }
  
  .custom_input button:hover {
    background: rgb(83, 45, 128);
  }
  
  .error {
    font-size: 16px;
    line-height: 24px;
    color: red;
  }
  
  .faq {
    background: #f6f6f6;
    margin-top: 80px;
    padding: 80px 0;
  }
  
  .faq h3 {
    text-align: center;
  }
  
  .faq__tabs {
    margin-top: 50px;
  }
  
  /* CallUs */
  .CallUs {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  
  .CallUs_img img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .CallUs_content h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
  }
  
  .CallUs_content p {
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px;
  }
  
  .CallUs_content h4 {
    font-size: 25px;
    line-height: 35px;
    color: rgb(83, 45, 128);
    font-weight: 700;
  }
  
  .CallUs_content a {
    background: #f27321;
    color: #fff;
    padding: 10px 30px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
    display: inline-block;
  }
  
  .react-tel-input .form-control {
    width: 100% !important;
    padding: 12px 20px !important;
    padding-left: 50px !important;
    border-radius: 10px !important;
    border: 1px solid #ddd !important;
    font-size: 18px !important;
    line-height: 25px !important;
    font-weight: 400 !important;
    color: #212529 !important;
  }
  
  .faq__tabs .accordion-body p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  
  .faq__tabs .accordion-body p span {
    color: rgb(110, 52, 182);
    font-weight: 600;
  }
  
  .faq__tabs .accordion-body p a {
    margin: 0 5px;
    text-decoration: none;
    color: #f27321;
    font-weight: 600;
  }
  
  /* BkashTableList */
  
  .BkashLeadsList{
    padding-bottom: 50px;
  }
  
  .BkashLeadsList .container {
    max-width: 90%;
  }
  
  .BkashLeadsList {
    margin: 50px 0;
  }
  
  .BkashLeadsList h2 {
    text-align: center;
    font-weight: 700;
    color: #f27321;
  }
  
  .BkashTableList table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .BkashTableList table td,
  .BkashTableList table th {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .BkashTableList {
    margin-top: 50px;
  }
  
  /* Meida */
  
  @media (max-width: 1023px) {
    .CallUs .col-lg-6:first-child {
      order: 2;
    }
  
    .CallUs .col-lg-6:last-child {
      order: 1;
    }
  
    .CallUs_content {
      text-align: center;
      margin-top: 20px;
    }
  
    .custom_input {
      width: 100%;
    }
  
    .custom_input label {
      font-size: 16px;
      line-height: 25px;
      width: 90%;
    }
  
    .BkashLeads__video iframe {
      height: 400px;
    }
  }
  
  @media (max-width: 667px) {
    .custom_input__child input {
      height: 15px;
      width: 15px;
      margin-top: 5px;
    }
  
    .custom_input__child {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      margin-top: 10px;
    }
  
    .BkashLeads__video iframe {
      height: 190px;
    }
  
    .CallUs_img img {
      height: auto;
    }
  
    .Social_sideIcon a {
      width: 40px;
      height: 40px;
    }
  }
  