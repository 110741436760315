.current-balance-section {
    background-color: #C2E1F0;
    border-radius: 15px;
    padding: 15px 25px;
}

.current-balance {
    font-size: 36px;
    font-weight: 700;
}

.payment-course-name {
    font-size: 28px;
    font-weight: 700;
}

.bundle-title {
    font-size: 24px;
    font-weight: 600;
    color: #532D80;
}

.bundle-book {
    background-color: #F4F1EB;
    padding: 20px 25px 3px 25px;
    border-radius: 12px;
}

.batch{
    background-color: #ddcdf1;
    padding: 15px;
    border-radius: 12px;
    position: relative;
}

.partial-balance {
    background-color: #FAE8DC;
    padding: 15px 25px 25px 25px;
    border-radius: 12px;
    position: relative;
}

.discount-percentage {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px 25px;
    background-color: #D0202F;
    color: #ffffff;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    font-weight: 500;
}

.admission-details {
    background-color: #E6E6E6;
    border-radius: 12px;
    padding: 15px 25px;
}

.admission-details p {
    font-size: 22px;
    font-weight: 500;
}

.admission-details-divider {
    height: 1px;
    width: 100%;
    background-color: #B0B0B0;
    margin: 12px 0;
}

.total-cost{
    padding: 0px 25px;
}

.total-cost p{
    font-size: 22px;
    font-weight: 500;
}

.promo-code-field{
    width: 100%;
    border: none;
    background-color: #F4F1EB;
    border-radius: 44px;
    font-size: 22px;
    padding: 15px 25px;
    font-weight: 400 !important;
    color:#000 !important;
}

.promo-code-field::placeholder {
  color: #7C7C7C !important;
  font-size: 22px !important;
}



.btn-apply{
    color: #fff;
    border: none;
    border-radius: 154px;
    padding: 13px 14px;
    background-color: #F27321;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
}



.payment-bottom{
    position: sticky;
    bottom: 0; 
    width: 100%;
    height: 100px;
    background-color: white;
   
}

.btn-admit-course{
    width: 100%;
    padding: 10px;
    background-color: #532D80;
    margin-top: 25px;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    border: none;
}
  


.btn-balance{
    border: none;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 12px;
}

/* .btn-balance:focus{
    border: 2px solid #532D80;
    border-radius: 12px;
} */

.form-check-input:checked {
    background-color: #532D80 !important;
    border-color: #532D80 !important;
}

.form-check-input:focus {
    border-color: #532D80;
    outline: 0;
    box-shadow: 0 0 0 0;
}

/* button active batch */
.btn_batch_active{
    border: 2px solid #532D80;
    border-radius: 12px;
}
.btn_balance_active{
    border: 2px solid #532D80;
    border-radius: 12px;
}


