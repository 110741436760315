.otp_input-area{
    width: 100%;
    background-color: #9174B2;
    opacity: 0.9;
    border-radius: 44px;
    border: none;
    color: white;
    font-size: 16px;
    font-size: 20px !important;
    font-weight: 400 !important;
    padding: 15px;
    text-align: center;
  }

  .otp_input-section{
    width: 100%;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
  }