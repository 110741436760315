.bg_color_blue {
    background-color: #532d80;
}

/* navbar */
.navbar-header {
    background-color: #F4F1EB;
    padding-top: 1rem;
}

.navbar-brand-box {
    width: 150px !important;
    background-color: #F4F1EB !important;
}

.option {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.option:hover {
    color: black;
}

.navbar-right {
    padding-right: 5rem;
}

.user-icon {
    margin-left: 1.5rem;
}

.profile-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-name {
    margin: 0px;
    padding: 0 .4rem 0 .5rem;
    cursor: pointer;
}


.profile-ul {
    padding: 0;
    list-style: none;
}

.profile-ul .profile-li {
    display: inline-block;
    position: relative;
    line-height: 21px;
    text-align: left;
}

/* sidebar  */
ul li a {
    display: block;
    padding: 8px 10px;
    color: #FFFFFF;
    text-decoration: none;
}



/* navigation bar */

@media only screen and (min-width: 1351px) {
    .text-nav {
        font-size: 18px;
    }

    .option {
        padding-left: 2rem;
    }
}

@media only screen and (min-width:1388px) and (max-width: 1436px) {
    .text-nav {
        font-size: 18px;
    }

    .option {
        padding-left: 1.8rem;
    }

    .user-name {
        font-size: 18px;
    }
}

@media only screen and (min-width:1341px) and (max-width: 1387px) {
    .text-nav {
        font-size: 1rem;
    }

    .option {
        padding-left: 1.6rem;
    }

    .user-name {
        font-size: 17px;
    }
}

@media only screen and (min-width:1294px) and (max-width: 1340px) {
    .text-nav {
        font-size: 1rem;
    }

    .option {
        padding-left: 1.4rem;
    }

    .user-name {
        font-size: 16px;
    }
}

@media only screen and (min-width:1247px) and (max-width: 1293px) {
    .text-nav {
        font-size: 1rem;
    }

    .option {
        padding-left: 1.2rem;
    }

    .user-name {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1246px) {
    .text-nav {
        font-size: 1rem;
    }

    .option {
        padding-left: 1rem;
    }

    .user-name {
        font-size: 15px;
    }
}

/* dropdown design */
.dropbtn {
    color: #532d80;
    padding: 0px 5px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #000000;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;
}

.dropdown-content .profile-menu {
    color: #ffffff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown .profile-menu:hover {
    background-color: #532d80;
}


/* .dropdown .profile-menu :hover img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(2%) saturate(22%) hue-rotate(301deg) brightness(87%) contrast(100%);
} */

.show {
    display: block;
}

.hide {
    display: none;
}

/* mobile responsive */
@media only screen and (max-width: 576px) {
    .show-mobile-view {
        display: block;
    }

    .hide-mobile-view {
        display: none !important;
    }

    .icon-font-size {
        font-size: 13px;
    }
}




/* notification */

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0em;
    content: none;
    border: none;
}

.notification-list{
    cursor: pointer;
}

.notification-list:hover {
    background-color: #d9d9d9 !important;
    color: #000000 !important;
    border-left: 3px solid #D0202F;
}

.notification .nav-link {
    padding: 0px !important;
}

.notification-menu {
    width: 400px;
}

@media (max-width: 576px) {
    .notification-menu {
        width: 290px;
    }
}

.margin_left_sm{
    margin-left: 100px !important;
}

.notification-top-title {
    color: #532D80;
    font-size: 20px;
    font-weight: 500;
}

.total-notification{
    background-color: #532D80;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 50%;
    padding: 4px 10px;
}
.notification-section ::-webkit-scrollbar {
    display: block !important;
    width: 5px !important;
  }
 
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #532D80 !important; 
    border-radius: 10px;
  }

.notification-bell{
    height: 40px;
    width: 45px;
    border-radius: 50%;
    background-color: #532D80;
    
}
.notification-bell img{
    height: 30px;
    width: 30px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(90%) saturate(2%) hue-rotate(111deg) brightness(109%) contrast(98%);
}
  
