.welcome-section{
    background-color: #FFCE39  !important; 
    border-radius: 1rem !important; 
    background-repeat: no-repeat !important; 
    background-position: right !important; 
    background-size:contain !important; 
    padding:.5rem .5rem !important;
}
.today-date-text{
    font-weight: 500;
}

.welcome-section-mobile-view{
    padding: 0;
}
.today-date{
    font-weight: 500;
}
@media only screen and (max-width: 576px) {
    .today-date-section{
        display: flex;
    }
    .today-date-text{
        padding-right: 5px;
        font-size: 14px;
    }

    .welcome-section-mobile-view{
       /*  padding: 0 1.5rem 1rem 1.5rem; */
       margin-bottom: 1rem;
    }
    .today-date{
        font-size: 14px;
    }
}