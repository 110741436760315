/* .faq_section {
    background-color: #EFEFEF;
    color: black;
    margin: 20px 0;
    border-left: 16px solid #532D80;
  }
  
  .question {
    font-size: 24px;
    font-weight: 600;
    padding: 33px 49px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .question::after {
    content: "\002B";
    text-align: center;
    background-color: #F27321;
    color: white;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position: absolute;
    right: 50px;
    transition: 0.2s;
    font-size: 1.5rem;
  }
  
  .question.active::after {
    content: "\2212";
    font-size: 1.5rem;
  }
  
  .answercont {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
  
  .answer {
    padding: 0 49px 33px 49px;
    line-height: 2.5rem;
    font-size: 20px;

  } */
  .question_section{
    background-color: #EFEFEF;
    color: black;
    margin: 20px 0;
    padding: 13px 34px;
    
  }

  .question {
    font-size: 24px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .accordion-button:not(.collapsed){
    box-shadow: none;
}

.answer {
  line-height: 2.5rem;
  font-size: 20px;

}

.accordion-header .accordion-button::after{
	background-image: url('../../../../../../../images/course_details_icon/Trailing-icon.png') ;
}

.accordion-header .accordion-button:not(.collapsed)::after{
	background-image: url('../../../../../../../images/course_details_icon/Chevron-up.png') ;
    transform: none !important;
}

@media only screen and (max-width: 576px) {
  .question_section{
    padding: 13px 10px;
    }

    .question {
      font-size: 15px;
    }
    .answer {
      line-height: 1.5rem;
      font-size: 16px;
    
    }

  }
