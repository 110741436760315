.leader-board-section{
    border: 1px solid #E9E9E9;
    border-radius: 16px;
}
.leader-board-title{
    font-size: 500;
    color: #918F92;
}

.position-icon{
    width: 6rem;
    vertical-align: middle;
    text-align: center;
}

.rank{
    width: 6rem;
    vertical-align: middle;
    font-size: 24px;
    font-size: 500;
    color: #918F92;
}

.leader-board-student-name{
    font-size: 20px;
    color: #232026;
    vertical-align: middle;
}
.leader-board-student-img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.leader-board-marks-section{
    vertical-align: middle;
}

.leader-board-marks{
    font-size: 20px;
    font-weight: 600;
    padding: 5px 12px;
    border-radius: 4px;
    color: #fff;
    background-color: #46C35F;
    vertical-align: middle;
    width: 8rem;
}

.leader-board-own-position{
    background-color: #EEEAF2;
}
.own-indicate{
    font-size: 12px;
    font-weight: 600;
    background-color: #AC6BEC;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
}

.leader-board-corner:last-child td:first-child { border-bottom-left-radius: 16px; }
.leader-board-corner:last-child td:last-child { border-bottom-right-radius: 16px; }


