.icon-image img{
    width: 84px;
    height: 84px;
}

/* Assignment table details */
.modal-assessment-details{
    width: 65%;
    margin: 0 auto;
    border: 1px solid #E9E9E9;
    border-radius: 16px;
    padding: 12px 24px;
}

.btn-submission-syllabus {
    border-radius: 8px;
    text-decoration: none;
    padding: 10.5px 24px;
    font-size: 16px;
    font-weight: 500;
    color: #532D80;
    border: 1px solid #532D80;
}
.btn-submission-syllabus:hover {
    color: #532D80;
}

.modal-assessment-details-text{
    font-size: 16px;
    font-weight: 400;
    color: #4F4D51;
}
.modal-assessment-details tr .assessment-details{
    padding: .7rem .5rem !important;
}

.modal-content {
    border: 1px solid #DEDEDE;
    border-radius: 16px;
}

/* Smart note details */
.smart-note-mentor-section{
    width: 65%;
    margin: 0 auto;
    background-color: #FDEADE;
    border-radius: 100px;
}
.smart-note-mentor-img img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.smart-note-mentor-img p{
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}