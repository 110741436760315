.student-profile .btn-close {
    box-sizing: content-box;
    width: 1.2em;
    height: 1.2em;
    padding: .25em .25em;
    color: #000;
    background: url("../../../../../../images/close-icon.png") center/contain;
    border: 0;
    border-radius: .25rem;
    opacity: 1;
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1;
}

.edit-profile {
    width: 30% !important;
}

.profile-user-img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.avatar-upload {
    position: relative;
    max-width: 205px;
    margin-bottom: 30px;
}

.avatar-edit {
    position: absolute;
    right: 40px;
    z-index: 1;
    top: 90px;
}

.avatar-edit #imageUpload {
    display: none;
}

.avatar-edit label {
    width: 40px;
    height: 40px;
    margin-bottom: -30px;
    margin-left: -70px;
    border-radius: 100%;
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-weight: normal;
    background: url("../../../../../../images/pencil-fill.png") center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0%), 0 2px 5px 0 rgb(0 0 0 / 19%);
    transition: all .2s ease-in-out;
}

.avatar-edit:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-edit:after {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 34px;
    margin: auto;
}

.student-profile-info{
    border: 1px solid #BDBCBE !important;
    border-radius: .5rem !important;
}

.student-profile-info::placeholder{
    font-size: 16px !important;
}

.btn-check:focus+.btn, .btn:focus {
    box-shadow: none;
}