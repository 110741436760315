.btn-edit-profile{
    font-weight: 600;
    color: #ffffff;
    background-color: #532D80;
    padding: 8px 24px;
    border-radius: 8px;
    border: none;
    font-size: 14px;
}

.info-title-area{
    width: 15rem;
    padding-left: 0rem !important;
    padding-bottom: 24px !important;
}

