.course-feature-border{
    background-color: #FFEADC;
    padding: 24px 18px 24px 18px;
    border-radius: 20px;
    margin-bottom: 42px;
}
.course-feature{
    border-radius: 20px;
    background-color: #FFF5EE;
    padding: 0 17px;
}

.course-feature-title{
    padding-top: 15px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.divider{
    height: 1px;
    width: 100%;
    background-color: #E8D1C2;
}

.course-feature-details{
    padding-top: 21px;
}