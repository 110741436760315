.rounded-t-5 {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .field-icon {
    float: right;
    margin-left: 200px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
  }

  @media (min-width: 992px) {
    .rounded-tr-lg-0 {
      border-top-right-radius: 0;
    }

    .rounded-bl-lg-5 {
      border-bottom-left-radius: 0.5rem;
    }
  }