@import './tailwind.css';

* {
    font-family: "KohinoorBangla", sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box; /* Ensures consistent box-sizing */
}

html, body {
    height: 100%;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */