.btn-view-lecture{
    display: block;
    text-align: center;
    border: 1px solid #E5DFEC;
    text-decoration: none;
    color: #532D80;
    font-size: 16px;
    font-weight: 500;
    padding: 11px 0;
    border-radius: 8px;
}

.btn-view-lecture:hover{
    color: #532D80;  
}