.fc .fc-col-header-cell-cushion {
    text-decoration: none;
    color: black;
}

.fc .fc-daygrid-day-number {
    text-decoration: none;
    color: black;
}

.fc-media-screen{
    background-color: white;
    padding: 2rem;
    border-radius: 15px;
}

.fc-col-header-cell{
    padding: .5rem !important;
}

.fc-button-group .fc-button{
    background-color: #F5F8FA;
    border: none;
    color: black;
}
.fc-h-event .fc-event-title-container {
    background-color: #532D80;
}
.fc .fc-button:hover {
    background-color: #532D80;
    color: white;
}

.fc-button-active {
    background-color: #532D80 !important;
}

.fc .fc-button:focus {
    outline: 0;
    box-shadow: none;
}

.fc-today-button{
    background-color: #532D80 !important;
    border: #532D80 !important;
}

.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
}
tr:first-child th:last-child {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.fc .fc-scroller-liquid {
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
}

.fc-listWeek-view, .fc-list-empty{
    border-radius: 15px !important;
}

.fc-day-today {
    background: #eee2fd !important;
  
} 

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: relative;
    min-height: 3em !important;
}

.fc .fc-list-event-dot, .fc-daygrid-event-dot {
    border: 5px solid #532D80;
}
.fc-daygrid-event-dot {
    border: 4px solid #532D80;
}

.fc-daygrid-event{
    color: #000000 !important;
    font-weight: normal;
}

.fc-daygrid-more-link{
    text-decoration: none;
    color: #532D80;
}

.fc-timegrid-event .fc-event-main {
    background-color: #532D80;
    border-color: #532D80;
    padding: 4px 0 0 4px !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    border: none;
}

.fc-list-day-side-text, .fc-list-day-text{
    color: black !important;
    text-decoration: none;

}

@media (max-width: 767.98px) {
    .fc .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: center;
    }

    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
    }
}

.date-event-section{
    height: 650px;
    overflow-y: auto;
}

.date-event-section ::-webkit-scrollbar {
    display: block !important;
    width: 5px !important;
  }
 
  /* Handle */
.date-event-section::-webkit-scrollbar-thumb {
    background: #532D80 !important; 
    border-radius: 10px;
  }

