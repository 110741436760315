.mentor-title {
    font-size: 20px;
    font-weight: 500;
}

.mentor-img {
    height: 45px;
    width: 45px;
}

.live-class-subject-img img{
    border-radius: 10px;
    border: 2px solid #707070;
}
.dashboard-course-divider {
    height: 1px;
    width: 98%;
    margin: 0 auto;
    background-color: #D4D4D4;
}

@media only screen and (min-width: 1264px) and (max-width: 1320px) {
    .live-class-date {
        font-size: 15px;
    }

    .course-name {
        font-size: 25px !important;
    }

    .mentor-title {
        font-size: 19px;
    }
}

@media only screen and (min-width: 1229px) and (max-width: 1263px) {
    .live-class-date {
        font-size: 14px;
    }

    .course-name {
        font-size: 22px !important;
    }

    .mentor-title {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1172px) and (max-width: 1228px) {
    .live-class-date {
        font-size: 13px;
    }

    .course-name {
        font-size: 20px !important;
    }

    .mentor-title {
        font-size: 17px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1171px) {
    .live-class-date {
        font-size: 12px;
    }

    .course-name {
        font-size: 18px !important;
    }

    .mentor-title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 577px) and (max-width: 1023px) {
    .course-name {
        font-size: 26px !important;
    }

    .mentor-title {
        font-size: 16px;
    }
}

@media only screen and (max-width: 576px) {
    .course-name {
        font-size: 16px !important;
        margin-bottom: 5px;
    }

    .course-topic {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .live-class-date {
        font-size: 10px;
    }

    .mentor-title {
        font-size: 12px;
    }

    .mentor-img {
        height: 35px;
        width: 35px;
    }

}
