.result-course-name{
    font-size: 20px;
    font-weight: 500;
}

.result-summary{
    color: #656367;
    font-weight: 400;
}
.total-mark-percentage{
    color: #46C35F;
    font-size: 20px;
    font-weight: 500;
}
.total-attend-assignment{
    color: #F27321;
    font-size: 20px;
    font-weight: 500
}

.result-details{
    width: 30%;
}
@media only screen and (min-width: 1732px) {
    .result-details{
        width: 30% !important;
    }
}
@media only screen and (min-width:1100px) and (max-width: 1731px){
    .result-details{
        width: 40% !important;
    }
}
@media only screen and (min-width:700px) and (max-width: 1099px){
    .result-details{
        width: 50% !important;
    }
}
@media only screen and (min-width:577px) and (max-width: 699px){
    .result-details{
        width: 60% !important;
    }
}

@media only screen and (max-width: 576px) {
    .result-details{
        width: 100%;
    }
}