.btn-new-admission{
    border: none;
    background-color: #F4F1EB;
    border-radius: 44px;
    width: 100%;
    padding: 15px 25px;
    font-size: 22px;
    font-weight: 500;
}

.btn-new-admission:hover{
    color: white;
    background-color: #532D80;
}

.btn-new-admission :hover .img-admit{
    filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(1453%) hue-rotate(150deg) brightness(111%) contrast(96%);
}