.page-content{
    /* margin-top: 8rem; */
}

.mid-section{
    height: 88%; 
    overflow-y: scroll;
}

.analysis-section{
    background-color: #F4F1EB; 
}
.live-class-row{
    padding: 0;
}

@media screen and (max-width: 576px) {
    .page-content{
        margin-top: 1rem;
    }
    /* .live-class-row{
        padding: 0 1.5rem;
    } */
}