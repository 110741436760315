.complete-title{
    font-size: 32px;
    font-weight: 500;
    margin-top: 40px;
}
.get-result-notice{
    font-weight: 400;
    margin-bottom: 40px;
}

.btn-submitAssignment-to-dashboard{
    background-color: #532D80;
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    border-radius: 8px;
}

.btn-submitAssignment-to-dashboard:hover{
    color: #ffffff;
}