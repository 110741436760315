.live-class-section{
    padding: 2rem; 
    position: fixed; 
    height:98%;
    background-color: rgba(211, 196, 229, .37); 
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
} 

.live-class-title{
    font-size: 24px;
    font-weight: 600;
}

.no-class-title {
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (min-width: 1732px) {
    .live-class-section{
        width: 66%;
    } 
    .offcanvas-end{
        width: 24%;
    } 
}

@media only screen and (min-width:1525px) and (max-width: 1731px){
    .live-class-section{
        width: 65%;
    }
    .offcanvas-end{
        width: 25%;
    } 
}
@media only screen and (min-width:1350px) and (max-width: 1524px){
    .live-class-section{
        width: 64%;
    }  
    .offcanvas-end{
        width: 27%;
    } 
}

@media only screen and (min-width:1235px) and (max-width: 1349px){
    .live-class-section{
        width: 63%;
    }
    .offcanvas-end{
        width: 29%;
    }  
}

@media only screen and (min-width: 1120px) and (max-width: 1234px){
    .live-class-section{
        width: 62%;
    } 
    .offcanvas-end{
        width: 32%;
    }  
}

@media only screen and (min-width: 1024px) and (max-width: 1119px){
    .live-class-section{
        width: 61%;
    }
    .offcanvas-end{
        width: 35%;
    }   
}
@media only screen and (min-width: 577px) and (max-width: 1023px){
    .live-class-section{
        width: 80%;
    }  
    .offcanvas-end{
        width: 40%;
    }

    .main-content {
        margin-left: 190px !important
    }

    .navbar-right {
        padding-right: 3rem;
    }
}

@media only screen and (max-width: 576px){
    .live-class-section{
        /* padding: 1rem 0rem 1rem 1rem; 
        position: relative; 
        height:100%;
        width: 100%;
        background-color: rgba(211, 196, 229, .37); 
        border-top-left-radius: .7rem; 
        border-top-right-radius: .7rem; */
        padding: 1rem 1rem 1rem 1rem;
        height:100%;
        width: 100%;
        position: relative;
        background-color: rgba(211, 196, 229, .37); 
        border-top-left-radius: .7rem; 
        border-top-right-radius: .7rem;
        margin-bottom: 10rem;
    } 

    .main-content {
        margin-left: 0 !important
    }

    .live-class-title {
        font-size: 18px;
        font-weight: 600;
    }
    .no-class-title{
        font-size: 14px;
        font-weight: 600;
    }
}

