.course-enroll{
    background-color: #532D80;
    opacity: 0.94;
    padding: 30px 19px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.price{
    font-size: 22px;
    font-weight: 600;
}

.offer-price{
    font-size: 40px;
    font-weight: 600;
}



.buy-course a, .buy-course p, .buy-course, .buy-course button {
    display: block;
    font-size: 20px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    background-color: #FFCE39;
    padding: 5px;
    border-radius: 5px;
    color: black;
    border: none;
    cursor: pointer; /* Ensure the cursor is a pointer to indicate clickability */
}

.buy-course {
    position: relative; /* Ensure the button is properly positioned */
}

.free-course-registration a{
    display: block;
    font-size: 20px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    padding: 9px 5px;
    border-radius: 5px;
    color: #000;
    border: 1px solid #532D80;
    margin-top: 12px;
}

.for-help{
    color:#EFEFEF;
    margin-top: 16px;
    margin-bottom: 35px;
}

.course-duration p{
    font-size: 20px;
    font-weight: 400;
   
}

.course-duration{
    padding-bottom: 20px !important;
}