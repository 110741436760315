.submission-view-top {
    font-size: 20px;
    font-weight: 500;
}

.font-400 {
    font-weight: 400;
}

.submission-show-marks {
    font-size: 18;
    font-weight: 600;
    color: #ffffff;
    background-color: #46C35F;
}

.marks {
    font-size: 28px;
    padding: 6px 0;
    margin-left: 4px;
}

.btn-again-submit {
    display: block;
    border-radius: 8px;
    width: 100%;
    text-decoration: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: #532D80;
    border: 1px solid #532D80;
}

.submission-top-section{
    border-radius: 16px;
    height: 18rem;
}

/* @media only screen and (min-width:992px) and (max-width: 1250px){
    .submission-top-section{
        border-radius: 16px;
        height: 22rem !important;
    }
} */

.submission-shortDescription {
    font-size: 18px;
    color: #656367;
}

.single-question-section-card {
    border-radius: 16px;
    border: 1px solid #FFEBB0;
}

.question-title-submission {
    font-size: 20px;
    font-weight: 500;
    
}

.question-type-submission{
    font-size: 12px;
    font-weight: 600;
    background-color: #66C2A5;
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
}

.card-title-question{
    background-color: #FFF8E1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 10px 16px;
}
.answer-sheet-section{
    background-color: #FFFDF5;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.question-answer-submission{
    font-size: 18px;
    font-weight: 400;
}
.single-question-mark{
    background-color: #F27321;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 8px;
    color: #ffffff;
    border-radius: 4px;
}

.single-question-remark{
    font-size: 20px;
    font-weight: 600;
    padding: 0px 8px;
}

.nav-pills .submission-count.active{
    background-color: #E5DFEC !important;
    color: black !important;
}
.assignment-submission .nav-link{
    background-color: #ffffff;
}

.question-title-submission p{
    margin-bottom: 0 !important;
}
