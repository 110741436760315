/*============== The below code is before gif set ============== */

.custom-modal .modal-dialog {
  width: 75vw;
  height: 30vh;
  max-width: 100%;
  max-height: 100%;
}

.custom-modal .modal-content {
  height: 85%;
  border-radius: 16px;
  overflow: hidden;
}