.free-class-form-section{
    background-color: #EFEFEF;
    padding: 34px 37px;
}

.free-class-inputForm{
    margin-bottom: 12px;
    border: 1px solid #D9D9D9;
    color: black;
    font-weight: 400 !important;
    background-color: #F6F6F6;
}

.input-height{
    height: 50px;
}

.form-select-lg{
    padding-left: 12px;
}

.form-select:focus, .form-control:focus{
    border-color: #D9D9D9;
    box-shadow: none !important;
}

.free-class-inputForm::placeholder{
color: #292929;
font-size: 20px;
}

.btn-freeClassFormSubmit{
    background-color: #FFCE39;
    font-size: 20px;
    font-weight: 500 !important;
    border: none;
    border-radius: 5px;

}