.review-section{
    margin-top: 6px;
    background-color: white;
    border-radius: 10px;
    padding: 14px 21px;
}

/*.image img{*/
/*    height: 48px;*/
/*    width: 48px;*/
/*    border-radius: 50%;*/
/*}*/

.reviewer_name{
    font-size: 20px;
    font-weight: 600;
}

.review_date{
    font-size: 14px;
    color: #848484;
}

.review{
    font-size: 16px;
    color: #292929;
    margin-top: 10px;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .reviewer_name, .review_date{
        text-align: center;
    }
    .review-section{
        padding: 14px 10px;
    }
    .review{
        text-align: justify;
    }
}