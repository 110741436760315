.author_detail-section{
    background-color: #EFEFEF;
    border-left: 12px solid #532D80;
}

.author_img{
    background:linear-gradient(to bottom right, #532D80, #FFCE39) border-box;
  border: 8px solid transparent;
  border-radius: 50%;
  float: right;
}

.authorName{
    font-size: 24px;
    font-weight: 600;
}

.author-des{
    margin-top: 42px;
}

.author-des p{
    font-size: 20px;
    text-align: justify;
    font-family: "Hind Siliguri", sans-serif !important;
}

.author-details-section{
    display: flex;
    align-items: center;
}


@media only screen and (max-width: 576px) {
    .author_img{
      float: none;
    }
    .author-image-section{
        display: flex;
        justify-content: center;

    }
    .author-details-section{
        display: flex;
        justify-content: center;
    }
    .author-details-section p{
        text-align: center;
    }
}