.blog-left {
    position: relative;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, .1) 100%);
    /* background-repeat: repeat-x; */
    overflow: hidden;
    cursor: pointer;
}

.blog-left img {
    object-fit: cover;
    opacity: 0.6;
 }


 .background {
    height: 300px;
    width: 100%;
    background-color: #3e2f60;
    background-size: 30px 30px;
    background-image:
      linear-gradient(to right, #4b3c6b 1px, transparent 1px),
      linear-gradient(to bottom, #453763 1px, transparent 1px);
  }
  .yellow {
    background-color: #ffce39;
  }
  

.inside-left-blog {
    position: absolute;
    bottom: 0;
    left: 0;
}
.left-blog-title{
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: .5rem;
}

.featured-blog{
    padding-right: 24px;
}
.skeleton-blog-img{
    height: 350px;
}
@media only screen and (max-width: 576px) {
    .left-blog-title{
        font-size: 24px;
        line-height: 2rem;
    }
    .featured-blog{
        padding-right: 12px;
    }
    .skeleton-blog-img{
        height: 230px;
    }
}

/* Ensure the appearance of the select dropdown */
.tw-appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* Position the arrow icon */
#category {
    background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19 9l-7 7-7-7"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 1rem center; /* Adjust as needed */
    background-size: 1.5rem;
}
