.borderTop{
    border-top: 2px solid #ffce39;
}

.terms-section{
    margin-top: 65px;
}
.title{
    font-size: 36px;
    font-weight: 600;
}

.details{
    font-size: 20px;
      font-weight: 500;
      line-height: 2.5rem;
      text-align: justify;
}

@media only screen and (max-width: 576px) {
    .title{
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    
    .details{
        font-size: 16px;
        font-weight: 500;
        line-height: 2rem;
        text-align: justify;
    }
}