@keyframes slideDown {
    from {
        margin-top: -100px;
    }

    to {
        margin-top: -60px;
    }
}



@keyframes slideUp {
    from {
        margin-top: -60px;
    }

    to {
        margin-top: -100px;
    }
}

.animate-slideDown {
    animation: slideDown .2s forwards;
}

.animate-slideUp {
    animation: slideUp .2s forwards;
}


