 .about-title {
     font-size: 48px;
     font-weight: 600;
     margin-top: 4rem;
 }

 .about-detail {
     font-size: 20px;
     font-weight: 500;
     text-align: justify;
 }

 .bg-img2 {
     margin-top: 3rem;
 }

 .bg-img1 {
     margin-top: 17rem;
     z-index: 100;
 }

 .bg-img3 {
     margin-top: 5.5rem;
     z-index: -1;
 }

 .trainer-count{
    margin-top: 10rem;
    padding-left: 7rem;
    font-size: 45px;
    font-weight: 700;
 }

 .trainer{
     font-size: 24px;
     padding: 0;
     font-weight: 400;
 }

 .bg-img4{
     height: 350px;
     width: 350px;
     margin-top: 10rem;
     z-index: -1;
     margin-right: 8rem;
 }

 .student-count{
     margin-top: 15rem;
     padding-right: 8rem;
     font-size: 45px;
     font-weight: 700;
 }

 .student{
     font-size: 24px;
     padding: 0;
     font-weight: 400;
 }

 .bg-img5-section{
     margin-top: 16rem;
     z-index: -1;
 }

 .course-count{
     margin-top: 6rem;
     padding-left: 7rem;
     font-size: 45px;
     font-weight: 700;
 }

 .course{
     font-size: 24px;
     padding: 0;
     font-weight: 400;
 }

 .details-width{
    width: 75%;
 }

 @media only screen and (max-width: 576px){
    .details-width{
        width: 95%;
     }
     .about-title {
        font-size: 30px;
        margin-top: 1rem;
    }
    .about-detail {
        font-size: 16px;
        font-weight: 500;
        text-align: justify;
    }
  }