* {
    font-family: 'KohinoorBangla', sans-serif;
    padding: 0;
    margin: 0;
  }

  html, body {
    margin:0;
    height:100%;
    /* overflow: scroll !important; */
  }

  /* .schedule-section{
    border-top: 2px solid #ffce39;
    top: 120px;
    z-index: 8;
  } */


  .custom {
    display: block;
    height: 100%;
    margin-right: 30px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    color: #532d80;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  .custom:after,
  .custom:before {
    content: "";
    position: absolute;
    width: 0%;
    display: block;
    height: 1px;
    margin-top: 2px;
  }
  
  .custom:after {
    bottom: 20%;
    left: 0;
  }
  
  .custom:before {
    top: 15%;
    right: 0;
  }
  
  .custom:hover::after {
    width: 100%;
    height: 3px;
    bottom: -35%;
    background-color: #532d80;
  }
  /* .custom:hover::before {
    width: 105%;
    height: 2px;
    top: 10%;
    background-color: #532d80;
  } */
  
  /* header part */
  
  .bottom-border {
    border-bottom: 2px solid #532d80;
    margin-bottom: 1px;
  }
  
  nav {
    padding: 1rem 3rem;
    background-color: #fefefe;
  }
  
  nav .container .navbar-collapse .navbar-nav .active {
    color: #532d80;
  }
  
  nav .container .navbar-collapse .navbar-nav .active:after,
  nav .container .navbar-collapse .navbar-nav .active:before {
    bottom: 0%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  @media only screen and (max-width: 991px){
    nav .container .navbar-collapse .navbar-nav .active:after,
    nav .container .navbar-collapse .navbar-nav .active:before {
      display: none;
    }
  }
  
  nav .container .navbar-collapse .navbar-nav .active:after {
    border-bottom-color: #fff;
    border-width: 0px;
    margin-left: 0px;
    z-index: 1;
  }
  nav .container .navbar-collapse .navbar-nav .active:before {
    border-bottom-color: #532d80;
    border-width: 10px;
    margin-left: -9px;
    padding-bottom: 23px;
    z-index: 1;
  }

  .category-name{
    font-size: 16px;
    font-weight: 600;
    color: #646464 !important;
  }



  .navbar-light .navbar-nav .nav-link{
    color: #000000 !important;
    padding: 5px 10px !important;
  }
  @media only screen and (min-width:992px) and (max-width: 1199px) {
    .navbar-light .navbar-nav .nav-link{
      font-size: 18px;
      padding: 5px 10px !important;
    }
   }
  
  .navbar-light .navbar-nav .nav-link.active{
    color: #532d80 !important;
  }
  .nav-pills .tabpen-links.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #f27321;
  }
  
  .nav-pills .tabpen-links,
  .nav-pills .show > .nav-link {
    color: #000000;
    background-color: #ececec;
  }
  
  .text_live_class,
  .text_free_class,
  .text_week,
  .balance_symbol {
    margin-bottom: 0;
    font-size: 16px;
  }

  .balance_symbol{
    font-weight: 700;
  }

  .discount_balance_text_size{
    font-size: 25px;
  }

  .actual_balance_text_size{
    font-size: 16px;
    font-weight: 500;
  }

  
  #free_class {
    margin-bottom: 0.5rem;
  }
  
  /* main part */
  /* top section */
  .home_background {
    width: 100%;
    height: 90%;
    background: url("../../images/bg_sliders.webp") center/contain;
    background-repeat: no-repeat;
    background-size: cover;
    /* border-top: 2px solid #ffce39; */
  }

  /* .top-yellow-line{
    width: 100%;
    height: 2px;
    background-color: #ffce39;
  } */
  .academic_background {
    width: 100%;
    height: 30%;
    background: url("../../images/yellow.png") center/contain;
    background-repeat: no-repeat;
    background-size: cover;
    /* border-top: 2px solid #ffce39; */
  }

  .skill_background {
    width: 100%;
    height: 30%;
    background: url("../../images/skills-banner.png") center/contain;
    background-repeat: no-repeat;
    background-size: cover;
    /* border-top: 2px solid #ffce39; */
  } 
  .review_background {
    width: 100%;
    height: 30%;
    background: url("../../images/bg_review.png") center/contain;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 2px solid #ffce39;
  }

  .faq_question{
    width: 100%;
    height: 100%;
    background: url("../../images/bg_pattern.png") center/contain;
    background-repeat: no-repeat;
    background-size: cover;
  }





  .top-boy-section {
    margin-top: -12rem;
  }

  .font-16{
    font-size: 16px;
}
  
  .text_color_org {
    color: #f27321;
  }

  .text_color_gray{
    color:#BEBEBE;
  }
  
  .text_color_blue {
    color: #532d80;
  }
  
  .bg_color_org {
    background-color: #f27321;
  }
  
  .bg_color_gray {
    background-color: #ececec;
  }
  
  .bg_color_blue {
    background-color: #532d80;
  }
  .border_all {
    border: 2px solid #532d80;
  }
  
  /* course section part */

  .course-section, .book-section{
    margin-top: 45px;
    font-size: 40px;
    font-weight: 600;
  }

  .book_web_view,
  .course_web_view {
    display: block;
  }
  
  .book_mobile_view,
  .course_mobile_view {
    display: none;
  }
  .card-text-decoration{
    text-decoration: none;
    color: #000000;
  }
  .card-text-decoration:hover{
    text-decoration: none;
    color: #000000;
  }



  .a_tag_decoration {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
  }

  .a_tag_decoration:hover{
    color: #f27321;
  }
  
  .card_body {
    height: auto;
    width: auto;
    border-radius: 1rem;
    background-color: #e6e4e4;
  }
  
  .book_border {
    border-radius: 1rem;
    /* height: 100%;
    width: 100%; */
  }
  .card-divider{
    width: 100%;
    height: 1px;
    background-color: #AAAAAA;
    margin: 15px 0 12px 0;
  }

  @media only screen and (max-width: 576px){
    .card-divider{
      margin: 8px 0 5px 0;
    }
    .discount_balance_text_size{
      font-size: 22px;
    }
  
    .actual_balance_text_size{
      font-size: 16px;
    }
  }
  
  .card_top_bg {
    background-image: url(../../images/cat_shape.png);
    height: 35px;
    width: 100px;
  }
  
  .card_top_course_title_position {
    padding-left: 1rem;
    padding-top: 5%;
  }
  
/*   .default-img-size{
    height: 255px;
  } */

  .course_book_name_size {
    font-size: 20px;
    font-weight: 500;
    padding-top: 1rem;
    height: 4.5rem;
    font-family: "Medium";
    /* margin-bottom: 6px; */
  }
  
  .card_top_book_title_position {
    padding-left: 2rem;
    padding-top: 5%;
    font-size: 1.125rem;
    font-weight: 500;
  }
  
  .button_border {
    border: 2px solid #f27321;
    margin-bottom: 0;
  }
  
  .btn-seeMore{
    border: none;
    border-radius: 5px;
    background-color: #532D80;
    color: white;
    font-size: 20px;
    font-weight: 300;
}
  /* get all things content section */
  .mojaru-content-section {
    background-color: #532d80;
    margin-top: 5rem;
  }
  
  .mojaru-content-section-mobile-view {
    display: none;
  }
  
  /* thinking content section */
  .mojaru-thinking-content-section {
    background-color: #f4f4f4;
    padding-bottom: 4rem;
  }
  #opinion-images-section {
    padding: 2rem 0rem;
    border-right: 2px solid #f4f4f4;
  }
  #mobile-view-identity-part {
    padding-top: 8rem;
  }
  .opinion-detail {
    background-image: url(../../images/quote.png);
    background-repeat: no-repeat;
    margin-left: auto;
    margin-top: 5rem;
  }
  #text {
    margin-top: 5rem;
  }
  
  /* how it work section */
  .first-part {
    background-color: #e6e4e4;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  }
  
  .second-part {
    background-color: #e6e4e4;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
  
  .third-part {
    background-color: #e6e4e4;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0 100%);
  }
  
  /* responsive section */
  
  @media only screen and (min-width: 601px) {
    .zoom-course {
      transition: transform 0.5s;
    }
  
    .zoom-course:hover {
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      cursor: pointer;
    }
  
    .book-animation {
      transition: 0.1s ease-in-out;
    }
  
    .book-animation:hover {
      margin-top: 5px;
      cursor: pointer;
    }
  }

  @media only screen and  (max-width: 1547px){
    #book-bottom-img {
      display: none;
    }
  }

  @media only screen and (max-width: 1400px){
    .top-boy-section {
      margin-top: -1rem;
    }
  
  }
  
  @media only screen and (max-width: 600px) {
    * {
      padding: 0;
      margin: 0;
    }
  
    nav .container .navbar-collapse .navbar-nav .active:before {
      border-bottom-color: #532d80;
      border-width: 0px;
      margin-left: 0px;
      padding-bottom: 20px;
      z-index: 1;
    }
  /* .schedule-section{
    border-top: 2px solid #ffce39;
    top: 45px;
    z-index: 8;
  } */
  
    #btn-login {
      margin: 1rem 0rem;
    }
  
   
    .mobile-view-space {
      margin-top: 1rem;
    }
  
    .book_web_view,
    .course_web_view {
      display: none;
    }
  
    .book_mobile_view,
    .course_mobile_view {
      display: block;
    }
  
    /* get all thing content section */
    .slider-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .mojaru-content-section-mobile-view {
      display: block;
    }
  
    .mojaru-content-section {
      display: none;
    }
  
    .slider {
      width: 100%;
      max-width: 600px;
      height: 400px;
      margin: 20px;
      text-align: center;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
    }
  
    .slides {
      display: flex;
      overflow-x: scroll;
      position: relative;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
    }
  
    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 100%;
      height: 400px;
      scroll-snap-align: center;
      margin-right: 0px;
      box-sizing: border-box;
      background: #532d80;
      transform-origin: center center;
      transform: scale(1);
    }
  
    .slide__text {
      margin-top: -3rem;
    }
  
    .slide a {
      background: none;
      border: none;
    }
  
    a.slide__prev,
    .slider::before {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: 5%;
    }
  
    a.slide__next,
    .slider::after {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      right: 5%;
    }
  
    /*   .slider::before,
    .slider::after,
    .slide__prev,
    .slide__next {
      position: absolute;
      top: 48%;
      width: 15px;
      height: 15px;
      border: solid white;
      border-width: 0 4px 4px 0;
      padding: 3px;
      box-sizing: border-box;
    } */
  
    .slider::before,
    .slider::after {
      content: "";
      z-index: 1;
      background: none;
      pointer-events: none;
    }
  
    .slider__nav {
      box-sizing: border-box;
      position: relative;
      bottom: 5%;
    }
  
    .slider__navlink {
      display: inline-block;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: white;
      margin: 0 10px 0 10px;
    }
  
    /* thinking content section */
  
    #mobile-view-identity-part {
      padding-top: 1rem;
    }
    #btn-opinion-more {
      padding-bottom: 3rem;
    }
    .opinion-detail {
      margin-top: 0px;
      padding-bottom: 1rem;
    }
    #opinion-images-section {
      padding: 0;
    }
  
    #text {
      margin-top: 0rem;
    }
  
    .people-images {
      display: flex;
      padding: 2rem 1rem;
      align-items: center;
    }
  
    #image {
      display: flex;
      align-items: center;
    }
    #up-arrow {
      transform: rotate(-90deg);
      padding: 0;
      display: none;
    }
    #down-arrow {
      transform: rotate(-90deg);
      padding: 0;
      display: none;
    }
    #middle-person {
      padding: 0 1rem;
    }
  
    /* how it work section */
    .first-part {
      background-color: #e6e4e4;
      border-radius: 0.5rem;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    .second-part {
      background-color: #e6e4e4;
      border-radius: 0.5rem;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  
    .third-part {
      background-color: #e6e4e4;
      border-radius: 0.5rem;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      margin-bottom: 5rem;
    }
    /* ask question section */
    #write-message {
      padding: 0;
    }
    #id {
      padding-right: 0;
    }
  
    #input {
      padding-left: 0px;
      padding-right: 0px;
    }
  
    .question-bg-top-img {
      z-index: -1;
    }
  
   /*  .book-mobile-view {
      height: 300px;
    } */
    .book-details-mobile-view {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .course_book_name_size {
      font-size: 16px;
      font-weight: 500;
      padding-top: .7rem;
      margin-bottom: 0.5rem;
    }
  
    .text_live_class,
    .text_free_class,
    .text_week,
    .balance_symbol {
      margin-bottom: 0;
    }
    #free_class {
      margin-bottom: 0rem;
    }
  }


  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    /* background-color: #532d80; */
    
  }


  .swiper-pagination-bullet {
      border-radius: 0% !important;
      width: 25px;
      height: 3px;
      background-color: #9e56f0;
  }

  .swiper-pagination-bullet-active {
      background-color: #490991;
  }

  .card-hover-effect {
    position: relative;
    top: 0;
    transition: all 0.3s ease;
  }

  .card-hover-effect:hover {
    top: -15px;
    cursor: pointer;
  }

  /* .card-hover-effect:hover{
    transform: translateY(-15px);
    box-shadow: 0px 10px 15px #ccc;
  } */




.shake-button {
  cursor: pointer;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.content-wrapper-margin {
  margin-top: 88px;
}

@media (max-width: 768px) {
  .content-wrapper-margin {
    margin-top: 70px;
  }
}
