
.certificate-middle-section{
    z-index: 3;
    border: 2px solid #D0C3A8;
}
.center-logo{
    max-height: 300px !important;
    min-height: 100px !important;
}

.certificate-title{
    font-family: 'Poppins', sans-serif;
    font-size: 3.3rem;
    font-weight: 700;
    line-height: 5rem;
    letter-spacing: .2rem;
}
.certificate-achievement{
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .7rem;
    /* line-height: .7rem; */
    
}

@media print {
    .certificate-line, .date-divider{
        print-color-adjust: exact;
    }
}

.date-divider{
    height: 2px; 
    width: 150px; 
    background-Color:#000;
}

.certificate-line{
    height: 5px;
    width: 65%;
    background-color: #FFD448;
    margin: 0 auto;
}

.ceo-name{
    text-decoration: overline;
    font-size: 20px;
}
.certificate-date-title{
    font-size: 20px;
}
.ceo-title{
    font-size: 18px;
    font-weight: 500;
}
.certificate-date{
    font-size: 18px;
    font-weight: 500;
}

.certificate-student-name{
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-top: 4rem;
    color: #6C3891;
}
.certificate-course-name{
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #6C3891;
}

.certificate-des{
    font-size: 18px;
    margin-bottom: 3rem;
}

.certificate-download-btn{
    display: inline-block;
        border-radius: 8px;
        text-decoration: none;
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
        background-color: #532D80;
        color: white;
        border: 1px solid #532D80;
}
@page {
    size: auto;
    margin: 0mm;
}
