.address-section{
    margin-bottom: 33rem;
}


@media only screen and (min-width: 992px) {
    .location{
        padding: 0 4rem;
        margin-top: 4rem;
        border-radius: 15px;
    } 
}

.address-title{
    padding-top: 6rem;
}
.address{
    font-size: 20px;
    color: white;
}
.address-details{
    background-color: #532D80;
    opacity: 0.94;
    padding-bottom: 10rem;
    border-radius: 1.3rem;
}
.contact-type img{
    height: 24px;
    width: 21px;
}
