.live-class-card{
    background-color: #fff;
    border-radius: 16px;
}
.course-title{
    color: #F38137;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 16px;
    background-color: #FEF8F4;
    display: inline;
}
.class-subject-name{
    font-size: 20px;
    font-weight: 500;
}
.class-date-view{
    border: 1px solid #E9E9E9;
    border-radius: 12px;
    padding: 12px;
    background-color: #FFFDEA;
    min-width: 90px;
}
.class-month{
    font-size: 12px;
    font-weight: 500;
}
.class-date{
    font-size: 32px;
    font-weight: 500;
}
.class-day{
    font-size: 12px;
    font-weight: 400;
}
.class-time-pointer{
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #DEDEDE;
}
.mentor-section{
    background-color: #EEEAF2;
    border-radius: 100px;
    padding: 4px;
}

  
  .avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  border: 1px solid #ffffff;
  
  }
  
  .avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
  }
  
  .avatar-group {
  display: flex;
  }
  
  .avatar-group .avatar-animated {
    margin-left: -20px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .avatar-group .avatar-animated:hover{
    transform: translate(-15px);
  }
  
  
  
  .avatar-group .hidden-avatars {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CBC0D9;
    font-weight: 500;
    color: #552D80;
    margin-left: -20px ;
  }
  .class-teacher-name{
    font-size: 16px;
    font-weight: 500;
  }
  .btn-class-join{
    font-weight: 500;
    padding: 10px 0;
    background-color: #532D80;
    border-radius: 8px;
    text-decoration: none;
    color: #fff;
    display:block;
  }

  .btn-class-join:hover{
    color: #fff;
  }
