@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 4px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #E6DBF9;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #6F39B7;
        border-radius: 100vh;
        /*border: 3px solid #f6f7ed;*/
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #6F39B7;
    }
}