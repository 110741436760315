body {
    font-family: "Hind Siliguri", sans-serif;
    /* background-color: #F4F1EB; */
    padding: 0;
    margin: 0;
  
  }

  /* p{
    font-size: 1rem;
  } */
  h2{
    font-size: 2rem;
  }
  h3{
    font-size: 1.75rem;
  }
  h4{
    font-size: 1.5rem;
  }
  h5{
    font-size: 1.25rem !important;
  }

  /* span{
    font-size: 1rem;
  } */
  
/* ::-webkit-scrollbar {
    display: none;
  } */
  .mid-section::-webkit-scrollbar {
    display: none !important;
  }
  .my-course-section::-webkit-scrollbar {
    display: none !important;
  }
  .vertical-menu::-webkit-scrollbar {
    display: none !important;
  }
  .student-background-color{
    background-color: #F4F1EB;
    height: 100vh;
  }
  #page-topbar {
   /*  position: sticky; */

    top: 0;
    right: 0;
    left: 0;
    /* z-index: 1002; */
  }

  @media (max-width:992px) {
    #page-topbar {
      /* position: fixed; */
      top: 0;
      right: 0;
      left: 0;
      /* z-index: 1002; */
    }
  }
  
  .navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    height: 70px;
    padding: 0 calc(24px / 2) 0 0
  }
  
  .navbar-header .dropdown.show .header-item {
    background-color: #f8f9fa
  }
  
  .navbar-brand-box {
    padding: 0 1.5rem;
    text-align: center;
    width: 250px
  }
  
  .header-logo {
    line-height: 70px
  }
  
  .header-logo .logo-sm {
    display: none
  }
  
  .logo-light {
    display: block
  }
  
  
  
  @media (max-width:992px) {
    .navbar-brand-box {
        width: auto
    }
  
    .header-logo span.logo-lg {
        display: none
    }
  
    .header-logo span.logo-sm {
        display: inline-block
    }
  }
  
/*   .page-content {
    padding: calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2)
  } */
  
  .header-item {
    height: 70px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #636e75;
    border: 0;
    border-radius: 0
  }
  
/*   html {
    overflow: scroll !important;
  } */
  
  
  body[data-sidebar=dark] .logo-light {
    display: block
  }
  
  @media (max-width:600px) {
    .navbar-header .dropdown {
        position: static
    }
  
    .navbar-header .dropdown .dropdown-menu {
        left: 10px !important;
        right: 10px !important
    }
  }
  
  @media (max-width:380px) {
    .navbar-brand-box {
        display: none
    }
  }
  
  body[data-layout=horizontal] .navbar-brand-box {
    width: auto
  }
  
  body[data-layout=horizontal] .page-content {
    margin-top: 70px;
    padding: calc(55px + 24px) calc(24px / 2) 60px calc(24px / 2)
  }
  
  @media (max-width:992px) {
    body[data-layout=horizontal] .page-content {
        margin-top: 15px
    }
  }
  
  
  
  .metismenu {
    margin: 0
  }
  
  .metismenu li {
    display: block;
    width: 100%
  }
  
  .metismenu .mm-collapse {
    display: none
  }
  
  .metismenu .mm-collapse:not(.mm-show) {
    display: none
  }
  
  .metismenu .mm-collapse.mm-show {
    display: block
  }
  
  .metismenu .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility
  }
  
  .vertical-menu {
    width: 160px;
    /* z-index: 980; */
    background: #fff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 80px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08)
  }
  @media (max-width:992px) {

  }
  

  /*  .vertical-menu {
    width: 180px;
    background: #fff;
    bottom: 0;
    margin-top: 0;
    position: relative;
    left: 0;
    top: 80px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08)
  } */
  .main-content {
    margin-left: 160px;
    overflow: hidden;
  }
  
  .main-content .content {
    padding: 0 15px 10px 15px;
    margin-top: 70px
  }
  
  
  
  @media (max-width:992px) {
    /* .vertical-menu {
        display: none
    } */
  
    /* .main-content {
        margin-left: 0 !important
    } */
  
    body.sidebar-enable .vertical-menu {
        display: block
    }
  }
  
  .vertical-collpsed {
    min-height: 1200px
  }
  
  .vertical-collpsed .main-content {
    margin-left: 100px
  }
  
  .vertical-collpsed .navbar-brand-box {
    width: 70px !important
  }
  
  .vertical-collpsed .header-logo span.logo-lg {
    display: none
  }
  
  .vertical-collpsed .header-logo span.logo-sm {
    display: block
  }
  
  .vertical-collpsed .vertical-menu {
    position: absolute;
    width: 70px !important;
    z-index: 5
  }
  
  .vertical-collpsed .vertical-menu .simplebar-content-wrapper,
  .vertical-collpsed .vertical-menu .simplebar-mask {
    overflow: visible !important
  }
  
  .vertical-collpsed .vertical-menu .simplebar-scrollbar {
    display: none !important
  }
  
  .vertical-collpsed .vertical-menu .simplebar-offset {
    bottom: 0 !important
  }
  
  
  
  body[data-sidebar=dark].vertical-collpsed {
    min-height: 1200px
  }
  
  body[data-layout=horizontal] .main-content {
    margin-left: 0 !important
  }
  
  body[data-sidebar-size=small] .navbar-brand-box {
    width: 160px
  }
  
  body[data-sidebar-size=small] .vertical-menu {
    width: 160px;
    text-align: center
  }
  
  body[data-sidebar-size=small] .main-content {
    margin-left: 160px
  }
  body[data-sidebar-size=small].vertical-collpsed .main-content {
    margin-left: 70px
  }
  
  .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent
  }
  
  .font-size-24 {
    font-size: 24px !important
  }
  