.offcanvas-backdrop{
    background-color: black !important;
}

.payment-offcanvas{
    height: 92%;
    overflow-y: auto;
}

/* class="fade offcanvas-backdrop show" */
.payment-back{
    background-color: #F2AE83;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    padding: 12px 16px;
    border-radius: 12px;
}
.payment-back:hover{
    background-color: #F27321;
}

.offcanvas-end.payment-select{
    width: 546px;
}

.latest-course-section {
    padding: 2rem;
    position: fixed;
    height: 98%;
    width: 90%;
    background-color: rgba(211, 196, 229, .37);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}