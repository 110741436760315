
.navbar-light .navbar-toggler{
    color: white;
}

.btn-primary:active:focus{
    box-shadow: none;
}
.top-header{
    padding: 0.5rem 0rem;
}

@media only screen and (max-width: 576px) {
    .top-header{
        padding: 0rem;
    }
}

@media only screen and (max-width: 992px) {
    .header-link, .header-contact{
        display: none !important;
    }
}

ul li a {
  display: block;
  padding: 0 !important;
  color: rgb(55 65 81);
  text-decoration: none;
}

.change-language{
    background-color: #fff;
    border: none;
    border-radius: 4px;
    padding: 2px 8px;
    margin-right: 15px;
}


.switch {
  position: relative;
  margin: 0 5px;
}

.switch > span {
  position: absolute;
  top: 9px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #fff;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #532d80;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #fff;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #532d80;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 100px;
  height: 35px;
  background-color: #fff;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
/*   -webkit-
  
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px; */
  border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #532d80;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}


input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}
.language-toggle-wrapper {
  position: absolute;
  top: 50%; /* Adjust this value as needed */
  transform: translateY(-50%);
  right: 20px; /* Adjust the right offset as needed */
}

  