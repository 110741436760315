.attendance-section{
    width: 75%;
    margin: 24px auto;
}

@media only screen and (max-width: 992px) {
    .attendance-section{
        width: 100%;
        margin: 24px auto;
    }
}

.attend-class-summary{
    font-size: 20px;
    font-weight: 500;
    color: #918F92;
}
.total-class-summary{
    font-size: 48px;
    font-weight: 400;
    color: #F27321;
}
.total-present-summary{
    font-size: 48px;
    font-weight: 400;
    color: #46C35F;
}
.total-absent-summary{
    font-size: 48px;
    font-weight: 400;
    color: #D0202F;
}

.atn-date{
    font-size: 20px;
    font-weight: 500;
    color: #232026;
}
.atn-day{
    font-size: 16px;
    font-weight: 400;
    color: #656367;
}

.attendance-present{
    background-color: #46C35F;
    border-radius: 4px;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    margin-right: 10px;
}
.attendance-absent{
    background-color: #D0202F;
    border-radius: 4px;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    margin-right: 10px;
}

.attendence_list{
    height: 500px;
    overflow-y: auto;
}

.attendence_list_section ::-webkit-scrollbar {
    display: block !important;
    width: 5px !important;
  }
 
  /* Handle */
.attendence_list_section::-webkit-scrollbar-thumb {
    background: #532D80 !important; 
    border-radius: 10px;
  }

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



