.course-question_section{
    background-color: #FFFFFF;
    color: black;
    margin: 8px 0;
    border-left: 12px solid #532D80 !important;
    padding: 0px 5px;
}

.course-faq-question{
    font-size: 20px !important;
}

.course-faq-answer{
    font-size: 16px;
    line-height: 1.5rem;
}