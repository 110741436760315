/* Hides horizontal scrollbar */
.custom-scrollbar::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.custom-scrollbar {
    -ms-overflow-style: none;
    /* Internet Explorer and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@media  (max-width: 376px) {
    .locationTag {
        margin-left: 110% !important;
    }
}

@media (min-width: 377px) and (max-width: 440px) {
    .locationTag {
        margin-left: 100% !important;
    }
}

@media (min-width: 441px) and (max-width: 500px) {
    .locationTag {
        margin-left: 60% !important;
    }
}

@media (min-width: 501px) and (max-width: 550px) {
    .locationTag {
        margin-left: 43% !important;
    }
}

@media (min-width: 551px) and (max-width: 700px) {
    .locationTag {
        margin-left: 30% !important;
    }
}