.auto-count-score{
    background-color: #46C35F;
    color: #fff;
    border-radius: 16px;
    padding: 17px;
}

.get-score{
    font-size: 48px;
    font-weight: 600;
}
.score-title{
    font-size: 24px;
    font-weight: 600;
}

.score-short-description{
    border: 1px solid #E9E9E9;
    border-radius: 16px;
    padding: 24px;
}
.score-short-description-total{
    border-bottom: 1px dashed #E9E9E9;
}