.banner-title{
    font-size: 35px;
    font-weight: 500;
}
.banner-text{
    margin-top: 2rem;
    font-size: 24px;
    font-weight: 300;
}

.skill-banner-textSection{
    padding: 15rem 0;
}

.skill-title{
    font-size: 40px;
    font-weight: 600;
}

@media only screen and (max-width: 576px)  {
    .banner-title{
        font-size: 28px;
    }
    .banner-text{
        margin-top: 1rem;
        font-size: 18px;
    }
    .skill-banner-textSection{
        padding: 2rem 0;
    }
    .skill-title{
        font-size: 22px;
        font-weight: 600;
    }
}