/* Add these media queries at the end of your CSS file */
.enroll-btn {
    background-color: #f27321;
    color: #ffffff;
    width: 120px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: 25px;
    padding: 1px 8px; /* Adjust padding as needed */
    border: none; /* Remove border */
    border-radius: 8px; /* Add border radius */
}

@media (max-width: 992px) {
    .position-relative {
        position: relative; /* Ensure the container retains its relative position */
    }
    .position-relative > div {
        position: static; /* Reset the position to static */
    }
    .position-relative .enroll-btn {
        position: relative; /* Adjust button's position */
        bottom: auto; /* Reset bottom */
        right: auto; /* Reset right */
        margin-top: 10px; /* Adjust top margin */
        width: 100%; /* Make button full width */
    }
}

@media (max-width: 768px) {
    .enroll-btn {
        width: 80px; /* Adjust width for smaller screens */
        bottom: 5px; /* Adjust bottom position */
        right: 10px; /* Adjust right position */
        font-size: 12px; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 576px) {
    .enroll-btn {
        width: 60px; /* Further adjust width for even smaller screens */
        bottom: 0; /* Adjust bottom position */
        right: 0; /* Adjust right position */
        font-size: 11px; /* Further adjust font size for even smaller screens */
    }
}
.marquee {
    /* Add your custom styles here */
    font-size: 18px;
    font-weight: bold;
    color: #333; /* Example color */
    background-color: #f0f0f0; /* Example background color */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Example box shadow */
  }
  .marquee-container {
    white-space: nowrap;
    overflow: hidden;
    width: 100%; /* Ensure the container takes full width */
  }
  
  .marquee-container img {
    display: inline-block;
    margin-right: 20px; /* Adjust spacing between images */
    animation: marquee 20s linear infinite; /* Adjust animation duration as needed */
  }
  
  .marquee-image {
    width: 30%; /* Adjust image width as needed */
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(220%); /* Start the animation from the right */
    }
    100% {
      transform: translateX(-100%); /* Move the images to the left */
    }
  }

  @media only screen and (max-width: 600px) {
    .marquee-image {
      width: 60%; /* Adjust image width for smaller screens */
    }
  }