.hero_bg_batch {
    height: auto;
    width: 100%;
    background: url("../../../../../images/batch/BG.png") no-repeat;
    background-size: cover;
}
.bg_demo_class {
    height: auto;
    width: 100%;
    background: url("../../../../../images/batch/BGdemo_class.png") no-repeat;
    background-size: cover;
}
