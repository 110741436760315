/* My course area css */
.my-course-section{
    padding: 2rem; 
    position: fixed; 
    height:88%;
    background-color: rgba(211, 196, 229, .37); 
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    overflow-y: auto;
}



@media only screen and (min-width: 1732px) {
    .my-course-section{
        width: 90%;
    } 
}

@media only screen and (min-width:1525px) and (max-width: 1731px){
    .my-course-section{
        width: 89%;
    }
}
@media only screen and (min-width:1350px) and (max-width: 1524px){
    .my-course-section{
        width: 87%;
    }
}

@media only screen and (min-width:1235px) and (max-width: 1349px){
    .my-course-section{
        width: 85%;
    }
}

@media only screen and (min-width: 1120px) and (max-width: 1234px){
    .my-course-section{
        width: 83%;
    } 
}

@media only screen and (min-width: 1024px) and (max-width: 1119px){
    .my-course-section{
        width: 82%;
    }  
}
@media only screen and (min-width: 577px) and (max-width: 1023px){
    .my-course-section{
        width: 82%;
        top: 9rem;
    }

    .main-content {
        margin-left: 160px !important
    }
}

@media only screen and (max-width: 576px){
    .my-course-section{
        padding: 1rem 1rem 1rem 1rem; 
        /* position: relative; */ 
        height:85%;
        width: 100%;
        background-color: rgba(211, 196, 229, .37); 
        border-top-left-radius: .7rem; 
        border-top-right-radius: .7rem;
        margin-bottom: 4rem;
    } 

    .main-content {
        margin-left: 0 !important
    }
}
/* My course area css */

.my-course-title{
    font-size: 32px;
    font-weight: 500;
}

