.assignment-card{
    border-radius: 16px;
}
.round-padding{
    padding: 16px;
}
.assignment-type{
    font-size: 12px;
    font-weight: 600;
    padding: 7px 15px;
    color: white;
    border-radius: 4px;
    margin-bottom: 16px;
}
.assignment-name{
    font-size: 20px;
    font-weight: 500;
}
.assignment-due-date{
    font-size: 18px;
    font-weight: 400;
}
.assignment-marks{
    background-color: #E8E8E8;
    padding: 12px 10px;
    border-radius: 50%;
}

.assignment-marks-after-submission{
    background-color: #46C35F;
    padding: 10px 14px;
    border-radius: 50%;
}

.no-marks{
    font-size: 18px;
    font-weight: 600;
    color: white;
}
.assignment-total-numbers, 
.assignment-total-questions, 
.assignment-total-attempts,
.assignment-total-time{
    font-weight: 400;
    margin-bottom: 0;
}

.btn-attempt{
    display: block;
    border-radius: 8px;
    width: 100%;
    text-decoration: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    background-color: #532D80;
    color: white;
    border:1px solid #532D80;
  }

  .cadet-btn{
    display: block;
    border-radius: 8px;
    width: 100%;
    text-decoration: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    background-color: #532D80;
    color: white;
    border:1px solid #532D80;
  }

  .cadet-btn:hover{
    color: white;
}

.btn-attempt:hover{
    color: white;
}

.btn-examover {
    display: block;
    border-radius: 8px;
    width: 100%;
    text-decoration: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    background-color: #D53E4F;
    color: white;
    border: 1px solid #D53E4F;
    margin-bottom: 0;
}