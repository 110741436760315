.lms-link{
    text-decoration: none;
    color: black !important;
    padding: 5px 18px;

}

.primary-section-img img{
    height: 148px;
    width: 240px;
    min-width: 100px;
    border-radius: 8px;
}

@media only screen and (max-width: 992px) {
    .primary-section-img img{
        display: none;
    }
}