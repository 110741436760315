@media screen and (min-width: 992px) {
    .wrapper {
        position: relative;
    }

    .wrapper img {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .banner-bottom-img {
        padding-left: 7rem;
    }

    .refund-section {
        margin-top: 65px;
    }
}

@media screen and (max-width: 991px) {
    .banner-top-img {
        height: 250px;
        width: 100%;
        padding: 0 1rem 1rem 1rem;
    }

    .refund-section {
        margin-top: 20px;
    }
}



.detail {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7rem;
}
