.purchase-book-card{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
}

.purchase-book-name{
    font-size: 18px;
    font-weight: 500;
    height: 4.5rem;
}

.purchase-book-write-name{
    color: #656367;
    font-size: 16px;
    font-weight: 400;
}
.modal-content-title{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #121212;
}
.modal-content-details{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #4A4A4A;
}

@media only screen and (min-width:1400px) and (max-width: 1560px) {
    .purchase-book-name{
        font-size: 18px;
        font-weight: 500;
        height: 6.5rem;
    }
}
