.event-card {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 39%), 0 21px 69px 0 rgb(0 0 0 / 12%);
    border: none;
    border-radius: 15px;
    position: relative;
}

.event-type {
    font-size: 16px;
}

.event-time {
    font-size: 13px;
}

.date-card {
    width: 50px;
    margin-top: -40px;
    margin-left: 75%;
    border-radius: 8px;
    background-color: #e6e6e9;
}

.date-body {
    text-align: center;
    padding: 0.3rem 0.3rem;
}

.date {
    margin-top: -5px;
    margin-bottom: 0;
    font-weight: 900;
}

.month {
    font-weight: 300;
}



