.schedule-class-section{
    /* background: #FAFAFA; */
   /*  height: 100vh; */
}

.orientation-course{
    border: 1px solid #FFCF0F;
    background-color: #FFFAE9;
    border-radius: 12px;
}



.schedule-course-title{
	padding-left: 12px;
	margin: 8px 0;
	font-weight: 500;
	
}


.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

/* FORM */

.orientation-course .plan input, .orientation-course .class-list input, .select-schedule-date .schedule-date input, .time-slot .slot-list input{
	display: none;
}

.orientation-course label{
	position: relative;
	color: #525252;
	background-color: #ece8e8;
	font-size: 18px;
	display: block;
	cursor: pointer;
	border: 1px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1rem;
	border-radius: 4px;
	font-weight: 500;
}

 .plan input:checked + label{
	border: 1px solid #46C35F;
	background-color: #C9F3D2;
}

 .plan input:checked + label:after{
	content: ".\2713";
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 100%;
	border: 1px solid #cf793f;
	background-color: #fff;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}


#classSlots-classList-section{
    border: 1px solid #532d80;
    background-color: #ddcdf1;
    border-radius: 12px;
}

#classSlots-classList-section label{
	position: relative;
	color: #000;
	background-color: #fff;
	font-size: 18px;
	display: block;
	cursor: pointer;
	border: 1px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1rem;
	border-radius: 4px;
	font-weight: 500;
}


 #classSlots-classList-section .class-list input:checked + label{
	border: 1px solid #cf793f;
	background-color: #FAE8DC;
}

  #classSlots-classList-section .class-list input:checked + label:after{
	content: ".\2713";
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 100%;
	border: 1px solid #cf793f;
	background-color: #fff;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}






.time-slot label{
	position: relative;
	color: #000;
	background-color: #fff;
	font-size: 18px;
	display: block;
	cursor: pointer;
	border: 1px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1rem;
	border-radius: 4px;
	font-weight: 500;
}
 .time-slot .class-list input:checked + label{
	border: 1px solid #cf793f;
	background-color: #FAE8DC;
}

  .time-slot .class-list input:checked + label:after{
	content: ".\2713";
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 100%;
	border: 1px solid #cf793f;
	background-color: #fff;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}






.select-schedule-date label{
	position: relative;
	color: #000;
	background-color: #f4f1eb;
	font-size: 18px;
	display: block;
	cursor: pointer;
	border: 1px solid transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1rem;
	border-radius: 4px;
	font-weight: 500;
}


.select-schedule-date .schedule-date input:checked + label{
	border: 1px solid #cf793f;
	background-color: #FAE8DC;
}

 .select-schedule-date .schedule-date input:checked + label:after{
	content: ".\2713";
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 100%;
	border: 1px solid #cf793f;
	background-color: #fff;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
	
}

.nav-pills .schedule-date{
    color: #000000;
    background-color: #ececec;
	position: relative;
}

.nav-pills .schedule-date.active::after {
  content: "\2713";
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 100%;
	border: 1px solid #cf793f;
	background-color: #fff;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}

/* .nav-pills .nav-link .schedule-date.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
} */
.nav-pills .nav-link.schedule-date.active{
	border: 1px solid #f27321;
	background-color: #fcceaf !important;
	color: #000 !important;
}


.btn-slot-booking{
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 4px;
	border: none;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	background-color: #532d80;

}

.slot-modal-description{
	font-size: 18px;
}
.slot-modal-description span{
	font-weight: bold;
}


















/* .BFS .btn-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    -webkit-animation: f 53s linear infinite;
    animation: f 53s linear infinite;
    background: linear-gradient(121.19deg,rgba(133,239,49,0) 25.73%,hsla(0,0%,100%,.3) 45.27%,rgba(133,239,49,0) 62.27%),#ef5b81 !important;
    color: #fff;
    border-radius: 60px;
    fill: currentColor;
    padding: 13px 0;
    transform: translateY(-3px);
}
 */






