.payment-subscript{
    color: #656367;
    font-weight: 500;
    font-size: 20px;
}
.subscription-balance-section{
    background-color: #FDEADE;
    border-radius: 12px;
    padding: 24px;
}
.subscription-balance{
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 400;
    color: #232026;
}

.btn-payment-control {
    padding: 0px 15px;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #532D80;
    border: none;
    border-radius: 8px;
    box-shadow: 0 6px #999;
  }
  
  .btn-payment-control:active {
    background-color: #532D80;
    box-shadow: 0 2px #666;
    transform: translateY(4px);
  }
  .payment-month{
    font-size: 24px;
    font-weight: 500;
  }
  .payment-subscript-details{
    padding: 16px 24px;
    background-color: #F4F4F4;
    border-radius: 12px;
  }
  .subscription-duration{
    font-size: 18px;
    font-weight: 400;
    color: #39363C;
  }

  .subscription-duration-total{
    font-size: 20px;
    font-weight: 500;
    color: #303030;
    padding: 0px 24px;
  }

  .subscription-promo-code-field{
    width: 100%;
    border: 1px solid #BDBCBE;
    background-color: #FFF8E1;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 25px;
    font-weight: 400 !important;
    color:#7C7C7C !important;
}

.btn-subscription-apply{
  color: #ffffff;
  border: 1px solid #F27321;
  border-radius: 12px;
  padding: 13px 25px;
  background-color: #F27321;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}