.accordion-item{
	margin-bottom: 10px;
    border: none !important;
}

/* .accordion-button {
    padding: 15px 0px;
    font-weight: bold;
    border: 0;
    font-size: 18px;
    color: #333333;
    text-align: left;
} */
.accordion-button:focus {
    box-shadow: none;
    border: none !important;
}


.accordion-button:not(.collapsed) {
    border: none !important;
    background: none;
    color: #4D4D4D;
}

.accordion-button {
    background: none;
    font-size: 20px;
    padding: 20px 15px;
    font-weight: 600;
}

.change .accordion-button::after{
	background-image: url('../../../../../../images/course_details_icon/Trailing-icon.png') !important;
}

.change .accordion-button:not(.collapsed)::after{
	background-image: url('../../../../../../images/course_details_icon/Chevron-up.png') !important;
    transform: none !important;
}

@media only screen and (max-width: 576px) {

    .month-count{
        font-size: 20px !important;
    }
    .accordion-button::after{
        height: 1rem !important;
        width: 1rem !important;
        background-size: 1rem !important;
    }
}