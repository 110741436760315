
.my_course_card_body{
  background-color: #ffffff;
  border-radius: 16px;
}
.custom-card-shadow{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.0), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
}
.purchased_course_name{
  font-size: 22px;
  font-weight: 500;
  font-family: "Medium";
}
.course-batch-name, .course-total-student{
  font-size: 18px;
  font-weight: 400;
}
.course-card-student-pointer{
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
}

.progress-bar-title{
  font-size: 14px;
  font-weight: 400;
  color:#918F92;
}
.btn-course{
  display: block;
  border: 1px solid #E5DFEC;
  border-radius: 8px;
  width: 100%;
  text-decoration: none;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #532D80;
  background-color: white;
}
.btn-course:hover{
  color: #532D80;
}

.responsive-course-image img{
    height: 250px;
}

@media only screen and (min-width:1200px) and (max-width: 1399px) {
    .responsive-course-image img{
        height: 210px;
    }
}
@media only screen and (min-width:992px) and (max-width: 1199px) {
    .responsive-course-image img{
        height: 180px;
    }
}
@media only screen and (min-width:577px) and (max-width: 991px) {
    .responsive-course-image img{
        height: 210px;
    }
}