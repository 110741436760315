.description-section{
    background-color: white;
    padding: 27px 31px 26px 40px;
    border-radius: 24px;
    margin-top: 16px;
}

.description-title{
    font-size: 24px;
    color: black;
    font-weight: 600;
    margin-bottom: 20px;
}

.description-details p{
    font-size: 20px;
}

.description-details{
    margin-bottom: 30px;
}


@media only screen and (max-width: 576px) {
    .description-section{
        padding: 15px;
    }
    
}