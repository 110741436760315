.progress-report-month-title{
    padding: 24px 24px 0 24px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}

.progress-report-month-title span{
    color: #532d80;
    font-size: 24px;
    font-weight: 600;
}