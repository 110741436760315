.profile-details-summary{
    background-color: #ffffff;
    padding: 24px;
    border-radius: 16px;
}
.student-name{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 8px;
}
.profile-completion{
    font-size: 400;
    font-size: 13px;
    margin-bottom: 0px;
}

.profile-verified{
    font-size: 12px;
    font-weight: 600;
    color:#46C35F;
    margin-bottom: 0;
    background-color: #EDF9EF;
    padding: 3px 10px;
    border-radius: 4px;
}