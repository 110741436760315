#input input, textarea{
    font-size: 20px !important;
    font-weight: 600 !important;
}

.form-control-lg{
    padding: 0.7rem 1rem !important;
}

.message_section{
    padding: 0 3rem;
}
.message-box-design{
    height: 300px;
    border-bottom: 1px solid #F4F4F4; 
    background-color:#292929;
}

@media only screen and (max-width: 576px){
    .message_section{
        padding: 0;
    }
}