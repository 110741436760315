.accordion-body {
    padding: 15px;
}
.month-class{
    margin-bottom: 22px;
}
.class-divider{
    height: 1px !important;
    width: 100%;
    background-color: #707070;
    margin-bottom: 16px;
}

.topic-name span{
    font-size: 20px;
    font-weight: 600;
}

.topic-name{
    font-size: 18px;
}

.min{
    font-size: 16px;
    color: #7C7C7C;
}

@media only screen and (max-width: 576px) {
    .topic-name span{
        font-size: 18px;
        font-weight: 600;
    }
    
    .topic-name{
        font-size: 16px;
    }
}