/* For mobile devices */
@media only screen and (max-width: 900px) {
    .display-flex {
        flex-direction: column;
    }
    .course-info {
        margin-top: 15px;
    }
    .payment-info {
        margin-top: 25px;
    }
    .div1 .div2 {
        width: 100%;
    }
    .position-promo-icon {
        right: 0;
    }
}

/* For tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .div1 {
        width: 58%;
    }

    .div2 {
        width: 38%;
    }
}
