.review-Section{
    padding: 0;
}

.accordion{
    color: black;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    /* transition: 0.4s;
    background-color: #EFEFEF; */
    margin-bottom: 20px;
}
.btn-items{
    display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-btn{
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem; 
}
.panel {
    margin-top: 15px;
    display: none;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  #write_review, #show{
    display: none;
    font-size: 24px;
    font-weight: 600;

    
}
#review_text{
    font-size: 24px;
    font-weight: 600;
}

#show{
    margin-bottom: 20px;
}

.review-divider{
    width: 100%;
    height: 2px;
    background-color: #D6D6D6;
    margin-bottom: 25px;
}

/* #rating, #message{
    background-color : #F6F6F6 !important; 
  } */

  .form-control:focus { 
        box-shadow: none; 
        transition: all 0.1s linear; 
        border-color: blueice!important; 
        box-shadow: inset 0px 0px 0px 0px blueice!important; 
    
}

.btn-review-submit{
    color: black;
    background-color: #FFCE39;
    border: none;
    font-size: 20px;
    font-weight: 400;
}

.review-count{
    color: black;
}

@media only screen and (max-width: 768px) {
    .review-Section{
        padding: 18px 15px;
    }
    #write_review, #review_text, #show{
        font-size: 18px;
    }
    .review-btn{
        font-size: 14px;
        padding: 0.25rem;
    }
}