.scroll-row {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    animation: scroll-horizontal 15s linear infinite;
}

@keyframes scroll-horizontal {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
