.course-subject-name{
    font-size: 18px;
}
.all-assignment-section{
    border-radius: 16px;
}
.course-total-assignment{
    font-size: 20px;
    font-weight: 500;
    padding: 24px 0 24px 24px;
}
.total-assignment-divider{
    width: 100%;
    height: 1px;
    background-color: #F4F4F4;
}

.assignment-subject .nav-link.active{
    background-color: #E5DFEC !important;
    color:#291640 !important;
    border-radius: 8px;
    text-decoration: none;
}
.assignment-subject .public-syllabus.active{
    background-color: #E5DFEC !important;
    color:#291640 !important;
    border-radius: 8px;
    text-decoration: none;
    /* padding: 8px 24px !important */;
}

.assignment-subject .nav-link{
    background-color: #ffffff !important ;
    font-size: 18px !important;
    text-align: left !important;
    /* padding: 8px 24px !important; */
}
.lms-subject-section{
    padding-left: 0;
}
@media only screen and (max-width: 992px) {
    .lms-subject-section{
        padding: 0;
    }
}