.logo img{
    height: 80px;
    width: 376px;
    margin-bottom: 43px;
}


.register-container{
  top: -5rem;
}

.bottom-text{
  bottom: -4rem;
  font-size: 24px;
}

.input-container{
    background-color: #532D80E5;
    border-radius: 15px;
    width: 45%;
    margin: 0 auto;
    padding: 3rem 82.5px 3rem 82.5px;
    opacity: 0.94;
}

.input-area{
    width: 100%;
    background-color: #9174B2;
    opacity: 0.9;
    border-radius: 44px;
    border: none;
    color: white;
    font-size: 20px;
    font-size: 20px !important;
    font-weight: 400 !important;
    padding: 13px 28px ;
  }

  ::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: white;
  }
 
  .input-section{
    width: 100%;
    margin: 0 auto;
  }
  
  input:focus{
    outline: none;
  }

  .btn_reg{
      margin-top: 25px;
      font-size: 28px !important;
      padding: 5px 45px;
      background-color: #F27321;
      border-radius: 12px;
      color: white;
      border: none;
  }


  .mobile_or_email::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #ffffff;
}
.mobile_or_email:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #ffffff;
    opacity: 1;
}
.mobile_or_email::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #ffffff;
    opacity: 1;
}
.mobile_or_email:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff;
}



  @media screen and (max-width: 992px) {
    .input-container{
        width: 70%;
        padding: 4rem 50px;
    }

    .logo img{
        height: 60px;
        width: 250px;
        margin-bottom: 35px;
    }
    .register-container{
      top: -1rem;
    }
    
    .bottom-text{
      bottom: -1rem;
    }
    
  }

  @media screen and (max-width: 576px) {
    .input-container{
        width: 95%;
        padding: 2rem 25px;
        margin-bottom: 30px;
    }
    .logo img{
        height: 40px;
        width: 200px;
    }
    .register-container{
      top: 0rem;
    }
    
    .bottom-text{
      bottom: 0rem;
      font-size: 18px;
    }
    
  }