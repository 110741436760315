.update-date {
    font-size: 20px;
    color: #848484;
    margin-bottom: 6px;
}

.course-name {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 3px;
}

.course-student {
    color: #7C7C7C;
}

.course-video {
    width: 100%;
    height: 530px;
    margin-top: 28px;
    margin-bottom: 41px;
}

.nav-tabs {
    border-bottom: none !important;
    margin-bottom: 0;
}

.nav-tabs .nav-link {
    border: none;
    background-color: #CDCDCD;
    color: black;
    border-radius: 0;
    width: 99%;
    font-size: 20px !important;
    font-weight: 500;
    padding: 12px 0 !important;
}

.nav-link {
    padding: 5px 1rem !important;
}

.profileTab .button {
    margin: 0;
}

.profileTab .profileNavLink.active {
    color: #FFFFFF;
    background-color: #F27321;
}

.profileTab .profileNavItem {
    width: 25%;
}

.profileTabContent {
    background-color: #EFEFEF;
}

.course-detail-top {
    margin-top: 50px;
}

/* .section-header {
    text-align: center;
    margin: 40px 0px 80px;
}
.section-header h3 {
    font-size: 22px;
    color: #2196f3;
    text-transform: uppercase;
}
.section-header h2 {
    font-size: 32px;
    color: #292929;
    position: relative;
}
.section-header h2:after {
    content: "";
    height: 3px;
    max-width: 100%;
    width: 120px;
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0px;
    margin: 0 auto;
} */








@media only screen and (max-width: 576px) {
    .update-date {
        font-size: 16px;
    }

    .course-name {
        font-size: 24px;
    }

    .course-video {
        height: 200px;
    }

    .nav-tabs .nav-link {
        font-size: 16px !important;
    }

    .course-detail-top {
        margin-top: 25px;
    }


}
