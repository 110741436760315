
.slick-dots li button:before {
  font-size: 12px;
}

.slick-dots li button:before {
  color: #ddd;
}

.slick-dots li.slick-active button:before {
  color: #555;
}

.slick-dots {
  bottom: -28px !important;
}

.slick-dots li {
  margin: 0 3px;
}

.slick-dots li button:before {
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  background-color: #3d166d;
  color: #fff;
}

.slick-dots li button:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d4d4d4;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply the animation to the active dot */
.slick-dots li.slick-active button:before {
  animation: pulse 1s ease-in-out infinite;
}