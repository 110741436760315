* {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .topDescription {
    font-size: 12px !important;
    padding-right: 100px !important;
  }
  .topTitle {
    font-size: 14px !important;
    padding-right: 100px !important;
  }
}

@media screen and (max-width: 400px) {
  .topTitle {
    font-size: 14px !important;
    padding-right: 150px !important;
  }
  .topDescription {
    font-size: 12px !important;
    padding-right: 150px !important;
  }
}
