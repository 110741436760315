.btn-lesson{
    font-size: 16px !important;
    font-weight: 500 !important;
}

.change .btn-lesson::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: url("../../../../../../images/arrow-down-square-fill.png") !important;
    background-repeat: no-repeat;
    background-size: 1em;
}

.change .btn-lesson:not(.collapsed)::after {
    background-image: url("../../../.../../../../../images/arrow-up-square-fill.png") !important;
}
.btn-lesson:not(.collapsed) {
    color: #000 !important;
}

.lesson-accordion-body{
    border: 1px solid #E9E9E9 !important;
    border-radius: 16px !important;
}




/* topic */
.topic-serial{
    width: 2.5rem;
    font-size: 16px;
    font-weight: 500;
    color: #232026;
    /* vertical-align: middle; */
}
.topic-category{
    width: 9rem;
    font-size: 16px;
    font-weight: 400;
    color: #656367;
}
.topic-details{
    width: 46rem;
    font-size: 16px;
    font-weight: 500;
    color: #232026;
}
.btn-topic-link{
    width: 12rem;
}

.video-lecture-icon{
    background-color: #3288BD;
    border-radius: 6px;
}
.video-smart-note-icon{
    background-color: #ABDDA4;
    border-radius: 6px;
}
.video-quiz-icon{
    background-color: #FDAE61;
    border-radius: 6px;
}
