.teacher-title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 22px;
}

.course-teacher{
    background-color: #FFCE39;
    padding: 37px 15px;
    border-radius: 20px;
}

.teacher-img img{
    border-radius: 50%;
    border: 2px dashed #F27321;
    padding: 4px;
    display: block;;
    margin: 0 auto;
    height: 116px;
    width: 116px;
}

.teacher-name{
    margin-top: 21px;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 8px;
}

.teacher-details{
    color:  #292929;
}

.teacher-divider{
    width: 100;
    height: 2px;
    background-color: #B5901F;
    margin: 20px 0;
}